import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

import { Box, Stack, useMediaQuery } from '@mui/system';
import {
  Card,
  TableRow,
  useTheme,
  TableCell,
  CardHeader,
  Typography,
  CardContent,
  CardActionArea,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { IJob, JobStatus } from 'src/services/jobs/jobs.types';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';
import {
  useGetConnectedIntegrationsQuery,
} from 'src/services/integration/integrations.service';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import NestedMenu from 'src/components/menu/nested-menu';
import { usePopover } from 'src/components/custom-popover';
import DateDisplay from 'src/components/date/date-display';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';
import VerificationStatusLabel from 'src/components/verification-status-label/verification-status-label';

import { JobStatusIcon } from 'src/sections/jobs/applications/jobs-status-icon';
import { ApplicationStatusLabel } from 'src/sections/jobs/applications/applications-status-label';

import { TenantType } from 'src/types/enums';

type JobTableRowProps = {
  row: IJob;
  disableControls?: boolean;
};

export default function JobTableRow({ row, disableControls = false }: JobTableRowProps) {
  const popover = usePopover();

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();

  const { currentData: connectedIntegrations, isLoading: isRetrievingConnectedIntegrations } =
    useGetConnectedIntegrationsQuery();

  const viewDetail = useCallback(() => {
    router.push(`${paths.dashboard.jobs.root}/${row.id}`);
  }, [router, row.id]);

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      label: t('common.view'),
      onClick: viewDetail,
      icon: <Iconify icon="solar:eye-bold" />,
    });

    return items;
  }, [t, viewDetail]);

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardActionArea>
          <CardHeader
            title={
              <Link
                to={`${paths.dashboard.jobs.root}/${row.id}`}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Link
                    to={`${paths.dashboard.jobs.root}/${row.id}`}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                      textWrap: 'nowrap',
                      textTransform: 'capitalize',
                    }}
                  >
                    <TruncatedTextField
                      text={row.title}
                      mode="characters"
                      limit={25}
                      showMoreEnabled={false}
                      variant="caption"
                      fontWeight="bold"
                      gutterBottom
                      style={{ textTransform: 'capitalize' }}
                    />
                  </Link>
                </Stack>
              </Link>
            }
            subheader={
              [TenantType.Candidate, TenantType.Recruiter].includes(tenantType)
                ? row.client_company_name || row.client?.company_name || `${t('jobs.detail.no_client')}`
                : ''
            }
            action={
              isOrganisationTenant(tenantType) && (
                <Stack direction="row" spacing={1}>
                  <Label variant="soft" color="info">
                    {row.departments?.[0]?.name ?? t('jobs.table.no_department')}
                  </Label>
                  {!!row.departments?.length && row.departments.length > 1 && (
                    <Label variant="soft" color="info">
                      +{row.departments.length - 1}
                    </Label>
                  )}
                </Stack>
              )
            }
          />
          <CardContent sx={{ position: 'relative', pr: 6, cursor: 'pointer' }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
              <ApplicationStatusLabel status={row.latest_application_status} />
            </Stack>

            {[TenantType.Candidate].includes(tenantType) && (
              <Stack direction="row" justifyContent="flex-start" flexGrow={1}>
                {Array.apply(null, Array(5)).map((val, _index) => (
                  <Iconify
                    key={_index}
                    sx={{
                      width: '10px',
                      height: '10px',
                      color:
                        _index < row.excitement_rating ? '#E5B80B' : theme.palette.text.disabled,
                      ':hover': {
                        color: '#E5B80B',
                      },
                    }}
                    icon={`solar:star-${row.excitement_rating >= 1 ? 'bold' : 'line-duotone'}`}
                  />
                ))}
              </Stack>
            )}

            <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 50 }}>
              <NestedMenu
                menuItems={menuItems}
                trigger={{ type: 'icon', icon: <Iconify icon="eva:more-vertical-fill" /> }}
              />
            </Box>
          </CardContent>
        </CardActionArea>

      </Card>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
          <JobStatusIcon status={row.status as JobStatus} />
          <Link
            to={`${paths.dashboard.jobs.root}/${row.id}`}
            style={{
              color: theme.palette.text.primary,
              fontWeight: 'bold',
              textWrap: 'nowrap',
              textTransform: 'capitalize',
            }}
          >
            <TruncatedTextField
              text={row.title}
              mode="characters"
              limit={25}
              showMoreEnabled={false}
              variant="subtitle2"
              fontWeight="bold"
              gutterBottom
              style={{ textTransform: 'capitalize' }}
            />
          </Link>
        </Stack>
      </TableCell>

      {[TenantType.Candidate, TenantType.Recruiter].includes(tenantType) && (
        <TableCell>
          {row.client_profile?.id ? (
            <Stack direction="row" spacing={1}>
              <Link
                to={`${paths.dashboard.connections.clients}/${row.client_profile.id}`}
                className="text-gray-800"
              >
                <TruncatedTextField
                  text={row.client?.company_name || row.client_company_name}
                  mode="characters"
                  limit={20}
                  showMoreEnabled={false}
                  variant="subtitle2"
                  fontWeight="bold"
                  gutterBottom
                  style={{ textTransform: 'capitalize' }}
                />
              </Link>
            </Stack>
          ) : (
            <Typography noWrap variant="subtitle2">
              <TruncatedTextField
                text={_.isEmpty(row.client_company_name) ? '-' : row.client_company_name}
                mode="characters"
                limit={20}
                showMoreEnabled={false}
                variant="subtitle2"
                fontWeight="bold"
                gutterBottom
                style={{ textTransform: 'capitalize' }}
              />
            </Typography>
          )}
        </TableCell>
      )}

      {tenantType === TenantType.Candidate && (
        <TableCell>
          {row.recruiter_profile?.id ? (
            <Stack direction="row" spacing={1}>
              <Link
                to={`${paths.dashboard.connections.recruiters}/${row.recruiter_profile.id}`}
                className="text-gray-800"
              >
                {row.recruiter_company_name}
              </Link>
            </Stack>
          ) : (
            <Typography noWrap variant="subtitle2">
              {_.isEmpty(row.recruiter_company_name) ? '-' : row.recruiter_company_name}
            </Typography>
          )}
        </TableCell>
      )}

      {isOrganisationTenant(tenantType) && (
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Label variant="soft" color="info">
              {row.departments?.[0]?.name ?? t('jobs.table.no_department')}
            </Label>
            {!!row.departments?.length && row.departments.length > 1 && (
              <Label variant="soft" color="info">
                +{row.departments.length - 1}
              </Label>
            )}
          </Stack>
        </TableCell>
      )}

      {isOrganisationTenant(tenantType) && <TableCell>{row.vacancies}</TableCell>}

      {
        [TenantType.Candidate, TenantType.Recruiter].includes(tenantType) && (
          <TableCell>
            {row.verification_status ? (
              <VerificationStatusLabel status={row.verification_status} />
            ) : (
              '-'
            )}
          </TableCell>
        )
      }

      <TableCell>
        <ApplicationStatusLabel status={row.latest_application_status} />
      </TableCell>

      <TableCell>
        <DateDisplay date={row.created_at} variant='caption' />
      </TableCell>

      <TableCell align="right" sx={{ pr: 1 }}>
        <NestedMenu
          menuItems={menuItems}
          trigger={{ type: 'icon', icon: <Iconify icon="eva:more-horizontal-fill" /> }}
        />
      </TableCell>
    </TableRow>
  );
}