import { useState, SyntheticEvent } from 'react';

import {
  Box,
  Grid
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetSkillsQuery } from 'src/services/utils/utils.service';

import { RHFAutocomplete } from 'src/components/hook-form';

export default function SkillsEditor() {

  const { t } = useTranslate();

  const [skillName, setSkillName] = useState('');

  const { currentData: skillsData } = useGetSkillsQuery({
    page: 1,
    per_page: 5,
    name: skillName,
  });

  const onSearchSkill = (_event: SyntheticEvent, value: string) => setSkillName(value);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFAutocomplete
            fullWidth
            freeSolo
            multiple
            onInputChange={onSearchSkill}
            name="skills"
            label={t('jobs.create.form.skills.label')}
            placeholder={t('jobs.create.form.skills.placeholder')}
            options={skillsData?.results?.map((option) => option.name) ?? []}
          />
        </Box>
      </Grid>
    </Grid>
  );
}