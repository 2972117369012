import type { FileResponse } from 'src/services/utils/utils.types';

import type { TenantType } from 'src/types/enums';

import { PageOptions } from '../api.types';
import { DocumentType } from '../files/file.types';
import type { AttachedThread } from '../threads/threads.types';
import type { Account, Organization } from '../auth/auth.types';
import { ComplianceRequirementType } from '../shared/shared.types';
import type { IJob, PeriodType, IJobQuestion, EmploymentType, Skill as ISkill } from '../jobs/jobs.types';
import type {
  IProfileLink,
  IWorkExperience,
  CandidateProfile,
  IEducationHistory,
  ISalaryExpectation,
  IPreferredLocation,
  CandidateProfileData,
  CandidateProfileSectionType,
} from '../candidates/candidates.types';

export interface RejectionContext {
  reason: RejectionReason;
  feedback?: string;
  offer?: string;
}

export interface IComplianceSubmission {
  id: string;
  question: IJobQuestion;
  answer: {
    text?: string;
  }
}

export interface ISubmissionData {
  question_id: string;
  submission_data: {
    text: string;
  };
}

export type ApplyIntent = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at: Date;
  status: string;
  resume: FileResponse;
  cover_letter: FileResponse;
}

export type Application = {
  id: string;
  stage: ApplicationStage;
  manager_type: TenantType;
  created_at: string;
  updated_at: string;
  rating: number;
  rejection_context?: RejectionContext;
  withdrawal_context?: WithdrawalContext;
  recruiter?: Pick<Account, 'id'> & {
    company_profile: { id: string; name: string; logo: { public_path: string } };
  };
  client?: Pick<Organization, 'id'> & {
    company_profile: { id: string; name: string; logo: { public_path: string } };
  };
  candidate: Pick<Account, 'id'>;
  job: Pick<IJob, 'id' | 'title' | 'employment_type' | 'verification_status'>;
  requested_stage: any; // stage
  manager_candidate_thread: AttachedThread;
  manager_client_thread: AttachedThread;
  internal_thread: AttachedThread;
  manager: {
    id: string,
    type: TenantType
  };
  sent_to_client_at: Date | null;
  open_status?: OpenStatus;
  attached_profile: Pick<CandidateProfile, 'id' | 'profile_image' | 'position_title' | 'updated_at'> & {
    first_name: string;
    last_name: string;
  };
};

export enum WithdrawalReason {
  APPLICATION_CONFLICT = 'application_conflict',
  CANDIDATE_ACTION = 'candidate_action',
}

export enum OpenStatus {
  OPENED = 'opened',
  NEW = 'new'
}

export interface WithdrawalContext {
  reason: WithdrawalReason;
}

export enum RejectionReason {
  OFFER_ACCEPTED = 'offer_accepted',
  OFFER_DECLINED = 'offer_declined',
  REJECTED = 'rejected',
  ROLE_CLOSED = 'role_closed',
  CONNECTION_UNLINK = 'connection_unlink',

  NOT_GOOD_FIT = 'not_good_fit',
  NOT_ENOUGH_EXPERIENCE = 'not_enough_experience',
  OTHER = 'other',
}

export enum ApplicationStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum ApplicationStage {
  INVITED = 'invited',
  INTERESTED = 'interested', // candidate only
  NO_APPLICATIONS = 'no_applications', // org only
  APPLIED = 'applied', // cand - recr
  INTERNAL_INTERVIEW = 'internal_interview', // candidate will see screening
  SCREENING = 'screening', // candidate will see screening
  READY_TO_SHORTLIST = 'ready_to_shortlist', // candidate will see screening
  SHORTLISTED = 'shortlisted', // Client On
  READY_TO_INTERVIEW = 'ready_to_interview',
  INTERVIEW = 'interview',
  REFERENCE_CHECK = 'reference_check',
  READY_TO_OFFER = 'ready_to_offer', // No candidate notification
  OFFER = 'offer',
  OFFER_ACCEPTED = 'offer_accepted',
  TO_BE_REJECTED = 'to_be_rejected',
  REJECTED = 'rejected',
  WITHDRAWN = 'withdrawn',
}

export type GetApplicationsQuery = {
  params: PageOptions & { search_query?: string };
  jobId?: string;
};

export type StageChangeRequest = {
  id: string;
  target_stage: ApplicationStage;
  status: StageChangeRequestStatus;
  rejection_context: RejectionContext;
  created_at: string;
  updated_at: string;
  application: Pick<Application, 'id'> & {
    attached_profile: Pick<CandidateProfile, 'id' | 'profile_image' | 'first_name' | 'last_name'>;
  };
};

export enum StageChangeRequestAction {
  Accept = 'accept',
  Defer = 'defer',
}

export enum StageChangeRequestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export type CreateOfferDto = {
  id?: string;
  position_title: string;
  employer_name: string;
  start_date: string;
  end_date: string | null;
  employment_type: EmploymentType;
  entitlements_inclusive: boolean;
  rate_period: PeriodType;
  pay_rate: string;
  notes?: string;
  required_documents?: DocumentType[];
  other_document_type?: string;
  submit_behaviour: 'save_as_draft' | 'submit';
};

export type Offer = {
  id: string;
  application_id: string;
  position_title: string;
  start_date: string;
  end_date: string | null;
  employer_name: string;
  employment_type: EmploymentType;
  entitlements_inclusive: boolean;
  rate_period: PeriodType;
  pay_rate: string;
  status: OfferStatus;
  compliance_requirements?: {
    item_type: any;
    requirement_type: ComplianceRequirementType;
    id: string;
    custom_type_name?: string;
  }[];
  compliance_requirements_notes?: string;
  notes?: string;
  candidate_name: string;
};

export enum OfferStatus {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  SENT = 'sent',
  CHANGES_REQUESTED = 'changes_requested',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  RESCINDED = 'rescinded',
}

export enum MatchStatus {
  MATCH = 'match',
  NO_MATCH = 'no_match',
  NOT_ENOUGH_DATA = 'not_enough_data'
}

export enum ApplicationInsightType {
  JOB_TYPE = 'job_type',
  SALARY = 'salary',
  WORK_STYLE = 'work_style',
}

export enum OfferAction {
  Accept = 'accept',
  Decline = 'decline',
}

export type Placement = {
  id: string;
  application: Application;
  candidate_account: {
    id: string;
    first_name: string;
    last_name: string;
  };
  placed_at: string;
};

export type ApplicationInsight = {
  id: string;
  match_status: MatchStatus;
  type: ApplicationInsightType;
  created_at: Date;
};

export enum ResourceType {
  Document = 'document',
  Reference = 'reference',
  ProfileInformation = 'profile_information',
}

export type InitiateResourceRequestProps = {
  applicationId: string;
  data: ResourceRequestData;
};

export type ResourceRequestData = {
  request_type: ResourceType;
  notes: string;
  documents?: { type: string }[];
  profile_sections?: { type: string }[];
};

export type ActionOfferRequestProps = {
  offerId: string;
  action: OfferAction;
  documents?: { type: string; profile_document_id: string }[];
};

export type ApplicationResourceRequest = {
  id: string;

  status: ResourceRequestStatus;

  type: ResourceType;

  items: ResourceItem[];

  created_at: Date;

  notes: string;
};

export enum ResourceRequestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}

export type ResourceItem = {
  id: string;
  item_type: string | CandidateProfileSectionType;
};

export type ActionResourceRequestProps = {
  requestId: string;
  data: {
    action: ResourceRequestAction;
    documents?: { profile_document_id: string; type: DocumentType }[];
    summary?: {
      content: string;
    };
    job_types?: CandidateProfileData[];
    work_styles?: CandidateProfileData[];
    education_level?: CandidateProfileData;
    work_rights?: CandidateProfileData[];
    salary_expectations?: ISalaryExpectation[];
    preferred_locations?: IPreferredLocation[];
    work_experiences?: IWorkExperience[];
    notice_period?: CandidateProfileData;
    skills?: ISkill[];
    education_history?: IEducationHistory[];
    links?: IProfileLink[];
  };
};

export type ActionResourceRequestData = ActionResourceRequestProps['data'];

export enum ResourceRequestAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}
