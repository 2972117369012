import { useSnackbar } from 'notistack';

import {
  Box,
  Grid,
  MenuItem
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';

import { RHFSelect } from 'src/components/hook-form/rhf-select';

export default function NoticePeriodEditor() {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFSelect
            fullWidth
            name="notice_period"
            label={t('enums.notice_period.input_label')}
          >
            <MenuItem key="default" value="">
              {t('common.none')}
            </MenuItem>
            {typeDefinitionData
              ? typeDefinitionData.NoticePeriodType.map((period: string) => (
                <MenuItem key={period} value={period}>
                  {t(`enums.notice_period.${period}`)}
                </MenuItem>
              ))
              : null}
          </RHFSelect>
        </Box>
      </Grid>
    </Grid>
  );
}