
import type { FileResponse } from 'src/services/utils/utils.types';

import { TenantType, TenantHierarchyLevel } from 'src/types/enums';

import { Product } from '../billing/billing.types';
import { AccountStatus } from '../account/account.types';
import { UserRole, SystemRole } from '../organisation/organisation.enum';
import type { User, OrganizationStatus, PaymentCollectionType } from '../organisation/organisation.types';

export interface RequestEmailVerificationDTO {
  email: string;
}

export interface CompletePasswordResetDTO {
  code: string;
  password: string;
}

export interface CreateAccountDTO {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  tenant_type: TenantType;
  terms_id: string;
  timezone: string;
  recaptcha_token: string;
}

export enum IntentType {
  APPLY = 'apply',
  CONNECT = 'connect',
}

export interface RegisterFromIntentDTO {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  terms_id: string;
  timezone: string;
  intent_type: IntentType;
  intent_token: string;
  recaptcha_token: string;
}

export interface AuthContext {
  user: Pick<User, 'id' | 'email' | 'email_verified' | 'first_name' | 'last_name' | 'phone' | 'profile_image'> & {
    status: AccountStatus;
    system_role: SystemRole;
    name: string;
    accounts_count?: number;
  };
  account: Account;
  organization: Organization;
}

export interface Account {
  id: string;
  status: AccountStatus;
  type: TenantType;
  org_role: UserRole;
  onboarding_context?: {
    [key: string]: boolean;
  };
  alert_context?: {
    [key: string]: boolean;
  };
  organization: ProfileOrganization;
}

export interface Organization {
  id: string;
  status: OrganizationStatus;
  type: TenantType;
  products: Product[];
  created_at?: Date;
  payment_collection_type?: PaymentCollectionType;
  invoice_due_days?: number;
  hierarchy_level?: TenantHierarchyLevel;
  sub_tenant_activated?: boolean;
}

export interface ProfileOrganization {
  id: string;
  company_name: string;
  company_information: string;
  company_type: string;
  status: OrganizationStatus;
  type: TenantType;
  hierarchy_level?: TenantHierarchyLevel;
  company_logo?: FileResponse;
}

export interface LoginDTO {
  username: string;
  password: string;
  recaptcha_token?: string;
}

export interface RegisterInviteDTO {
  first_name: string;
  last_name: string;
  password: string;
  timezone: string;
  terms_id: string;
  invite_token: string;
  recaptcha_token?: string;
}
