import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { MobileDatePicker } from '@mui/x-date-pickers';
import {
  Button,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { generateUploadError } from 'src/services/files/file.utils';
import { useUploadFileMutation } from 'src/services/files/file.service';
import { DocumentType, CandidateDocumentTypes } from 'src/services/files/file.types';
import { useAttachDocumentToProfileMutation } from 'src/services/candidates/candidates.service';

import { Upload } from 'src/components/upload';
import UploadItemCard from 'src/components/upload/upload-item-card';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  profileId: string;
  type?: DocumentType;
  onUpload?: (profileDoc: any) => void;
};

export default function UploadCandidateDocumentModal({
  open,
  onClose,
  profileId,
  type,
  onUpload = (doc: any) => { },
}: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [selectedDocType, setSelectedDocType] = useState<DocumentType | null>(type || null);

  const [selectedVisiblity, setSelectedVisiblity] = useState<VisibilityRule>(VisibilityRule.HIDDEN);

  const [file, setFile] = useState<File>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [uploadFile, { isLoading: uploadingFile }] = useUploadFileMutation();

  const [attachDocument, { isLoading: isAttaching }] = useAttachDocumentToProfileMutation();

  const handleDropDocument = useCallback(async (documents: File[]) => {
    setFile(documents[0]);
  }, []);

  const handleDocumentUpload = useCallback(async () => {
    try {
      const uploadedFile = await uploadFile({
        file: file as File,
        type: 'document' as any,
      }).unwrap();

      const attachDocResponse = await attachDocument({
        profileId,
        data: {
          file_id: uploadedFile?.id as string,
          type: selectedDocType as DocumentType,
          visibility_rule: selectedVisiblity,
          expiry_date: selectedDate?.toISOString(),
          name: uploadedFile.filename,
        },
      }).unwrap();

      setFile(undefined);
      setSelectedDocType(null);
      setSelectedVisiblity(VisibilityRule.PUBLIC);
      setSelectedDate(null);

      onUpload(attachDocResponse);

      enqueueSnackbar(t('profile.api.upload_document.success'), { variant: 'success' });

      onClose();
    } catch (e) {
      enqueueSnackbar(generateUploadError(e), { variant: 'error' });
    }
  }, [
    onUpload,
    uploadFile,
    file,
    attachDocument,
    profileId,
    selectedDocType,
    selectedVisiblity,
    selectedDate,
    enqueueSnackbar,
    t,
    onClose,
  ]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('profile.documents.upload')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pb: 0, pt: 2 }}>
          <Grid xs={12}>
            <FormControl fullWidth>
              <InputLabel id="doc-type-label">
                {t('jobs.detail.documents.document_type')}
              </InputLabel>
              <Select
                id="doc-type"
                disabled={!!type}
                labelId="doc-type-label"
                label={t('jobs.detail.documents.document_type')}
                value={selectedDocType}
                onChange={(e) => setSelectedDocType(e.target.value as DocumentType)}
                fullWidth
              >
                {CandidateDocumentTypes.map((docType) => (
                  <MenuItem key={docType} value={docType}>
                    {t(`documents.file_type.${docType}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6}>
            <FormControl fullWidth sx={{ width: '100%' }}>
              <InputLabel id="doc-type-label">{t('profile.documents.visiblity')}</InputLabel>
              <Select
                id="doc-type"
                labelId="doc-type-label"
                label={t('profile.documents.visiblity')}
                value={selectedVisiblity}
                onChange={(e) => setSelectedVisiblity(e.target.value as VisibilityRule)}
                fullWidth
              >
                <MenuItem value={VisibilityRule.PUBLIC}>
                  {t('enums.visibility_rule.public')}
                </MenuItem>
                <MenuItem value={VisibilityRule.HIDDEN}>
                  {t('enums.visibility_rule.hidden')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6}>
            <MobileDatePicker
              label={t('profile.documents.expiry_date')}
              value={null}
              onChange={setSelectedDate}
              sx={{ width: '100%' }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'today', 'cancel', 'accept'],
                },
              }}
            />
          </Grid>

          <Grid xs={12}>
            <Upload
              accept={{
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                  '.docx',
                ],
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg', '.jpg'],
              }}
              onDrop={handleDropDocument}
              sx={{ mt: 1 }}
            />
          </Grid>

          <Grid xs={12}>
            {file && (
              <UploadItemCard
                title={(file as any).path}
                onDeleteFile={() => setFile(undefined)}
                uploading={uploadingFile}
              />
            )}
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isAttaching || uploadingFile}
              disabled={!file || !selectedDocType}
              onClick={handleDocumentUpload}
            >
              {t('common.upload')}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
