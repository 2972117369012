import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/system';
import {
  Box,
  Card,
  Grid,
  Stack,
  Tooltip,
  CardHeader,
  IconButton,
  Typography,
  CardContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { IEducationHistory } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';

import { EducationHistoryEditModal } from 'src/sections/profile/profile-sections/profile-education-history';

export default function EducationHistoryEditor() {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  const { watch, setValue, getValues } = useFormContext();

  const theme = useTheme();

  const showEducationEditorModal = useBoolean();

  const [selectedHistoryItem, setSelectedHistoryItem] = useState<IEducationHistory | undefined>(undefined);

  const educationHistory: IEducationHistory[] = watch('education_history');

  const onSaveExperiences = useCallback((items: IEducationHistory[]) => {
    setValue('education_history', items);
    showEducationEditorModal.onFalse();
  }, [setValue, showEducationEditorModal]);

  const onUpdateHistory = (item: IEducationHistory) => {
    const existingExperienceIndex = educationHistory.findIndex((exp: IEducationHistory) => exp.id === item.id);
    let updatedExperiences = [];

    if (existingExperienceIndex !== -1) {
      updatedExperiences = [...educationHistory];
      updatedExperiences[existingExperienceIndex] = item;
    } else {
      updatedExperiences = [item, ...educationHistory];
    }

    onSaveExperiences(updatedExperiences);
  };

  const onRemoveHistory = useCallback((itemIndex?: number) => {
    const updatedExperiences = educationHistory.filter((_: IEducationHistory, index: number) => index !== itemIndex);

    onSaveExperiences(updatedExperiences);
  }, [educationHistory, onSaveExperiences]);

  const onCloseEditor = () => {
    setSelectedHistoryItem(undefined);
    showEducationEditorModal.onFalse();
  };

  const onOpenEditor = (experienceIndex?: number) => {
    const selectedExp = experienceIndex !== undefined ? educationHistory[experienceIndex] : undefined;
    if (selectedExp) {
      setSelectedHistoryItem(selectedExp);
    }

    showEducationEditorModal.onTrue();
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <Stack spacing={2} display="flex" justifyContent="flex-start">
            {educationHistory?.length ? (
              educationHistory.map((education: IEducationHistory, index: number) => (
                <Card
                  key={index}
                  id={`${education.end_year}-${education.institution_name}`}
                  sx={{ backgroundColor: theme.palette.background.default }}
                >
                  <CardHeader
                    sx={{
                      flexWrap: 'wrap'
                    }}
                    title={education.certification_name}
                    subheader={`${education.institution_name} ${education.start_year} - ${education.end_year}`}
                    action={
                      <Stack direction="row">
                        <IconButton
                          className="text-gray-500"
                          onClick={() => onOpenEditor(index)}
                        >
                          <Iconify icon="solar:pen-bold" />
                        </IconButton>
                        <IconButton
                          className="text-gray-500 delete-experience-button"
                          onClick={() => onRemoveHistory(index)}
                        >
                          <Iconify icon="mdi:delete" />
                        </IconButton>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Grid container wrap="wrap">
                      <Grid xs={12} md={6}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {education.institution_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography textAlign="center" variant='subtitle2' className="text-gray-400">
                {t('profile.sections.education_history.no_education')}
              </Typography>
            )}
          </Stack>

          <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
            <IconButton
              color='primary'
              size='large'
              className="text-gray-500"
              onClick={() => onOpenEditor()}
            >
              <Tooltip title={t('profile.sections.education_history.add_experience')}>
                <Iconify icon="carbon:add-filled" width={30} height={30} />
              </Tooltip>
            </IconButton>
          </Stack>
        </Box>
      </Grid>

      <EducationHistoryEditModal
        open={showEducationEditorModal.value}
        onClose={onCloseEditor}
        experience={selectedHistoryItem}
        addExperience={onUpdateHistory}
      />
    </Grid>
  );
}