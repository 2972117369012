import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions'; // Add this import

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  ISalaryExpectation,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import FormProvider from 'src/components/hook-form';
import RHFSalaryPicker from 'src/components/hook-form/rhf-salary-range-picker';
import RHFVisibilityToggle from 'src/components/hook-form/rhf-visibility-toggle';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileSalaryEditor({ profile, isLoading, mode }: Props) {
  const [updateProfile] = useSetCandidateProfileMutation();

  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    salary_expectations: [],
    salary_expectations_visibility: true,
  };

  const validationSchema = Yup.object().shape({
    salary_expectations: Yup.array().of(
      Yup.object()
        .shape({
          min_amount: Yup.number().defined(),
          max_amount: Yup.number().defined(),
          period: Yup.string().defined(),
        })
        .defined()
    ),
    salary_expectations_visibility: Yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    watch,
    reset,
    setValue,
    getValues,
  } = formMethods;

  const setProfileData = () => {
    if (!profile || isLoading) return;

    reset(
      {
        salary_expectations: profile?.salary_expectations ?? [],
        salary_expectations_visibility:
          profile.sections?.find(
            (section) => section.section_type === CandidateProfileSectionType.SALARY_EXPECTATIONS
          )?.visibility_rule === VisibilityRule.PUBLIC,
      },
      { keepDirty: false }
    );
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onAddSalaryExpectation = (salary: ISalaryExpectation) => {
    const { salary_expectations } = getValues();

    setValue('salary_expectations', [...(salary_expectations || []), salary], {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const onRemoveSalaryExpectation = (salaryIndex: number) => {
    const { salary_expectations } = getValues();

    if (!salary_expectations?.length) return;

    salary_expectations.splice(salaryIndex, 1);
    setValue('salary_expectations', [...salary_expectations], {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          salary_expectations: data.salary_expectations ? data.salary_expectations : undefined,

          sections: [
            {
              type: CandidateProfileSectionType.SALARY_EXPECTATIONS,
              visibility_rule: data.salary_expectations_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
          ],
        },
      }).unwrap();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  const salary_expectations_visibility = watch('salary_expectations_visibility');

  const renderForm = () => (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={200} />
          ) : (
            <RHFSalaryPicker
              name="salary_expectations"
              onAddSalary={onAddSalaryExpectation}
              onRemoveSalary={onRemoveSalaryExpectation}
              aria-label="Salary Expectation Slider"
              disabled={isLoading || mode === 'view'}
            />
          )}
        </Grid>
      </Grid>
      {mode === 'edit' && (
        <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={120} height={40} />
          ) : (
            <RHFVisibilityToggle
              name="salary_expectations_visibility"
              disabled={isLoading}
              labelPosition="left"
              checked={salary_expectations_visibility}
              onLabel={t('common.visible')}
              offLabel={t('common.hidden')}
              tooltip={t('profile.form.toggle_tooltip')}
            />
          )}

          <LoadingButton
            variant="contained"
            color="success"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || !isDirty || isLoading}
          >
            {t('common.save')}
          </LoadingButton>
        </CardActions>
      )}
    </FormProvider>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.salary.title')}
        subheader={isLoading ? <Skeleton width={300} /> : t('profile.sections.salary.alert')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
