import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Chip, Alert, Button, MenuItem, DialogActions } from '@mui/material';

import { useTranslate } from 'src/locales';
import { DocumentType } from 'src/services/files/file.types';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';
import { ResourceType, ResourceRequestData } from 'src/services/applications/applications.types';
import { useInitiateResourceRequestMutation } from 'src/services/applications/applications.service';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFTextField, RHFMultiSelect } from 'src/components/hook-form';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  applicationId: string;
};

const request_types = [
  ResourceType.Document,
  ResourceType.ProfileInformation,
  ResourceType.Reference,
];

export default function ResourceRequestModal({ open, onClose, applicationId }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const { currentData: typeDefinitions, isLoading } = useGetTypeDefinitionsQuery();

  const [initiateRequest] = useInitiateResourceRequestMutation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const requestSchema = Yup.object().shape({
    request_type: Yup.string(),

    notes: Yup.string().nullable(),

    documents: Yup.array().nullable(),

    profile_sections: Yup.array().nullable(),
  });

  const defaultValues = {
    request_type: ResourceType.Document,
    notes: '',
    documents: null,
    profile_sections: null,
  };

  const methods = useForm({
    resolver: yupResolver(requestSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = methods;

  const requestType = watch('request_type');

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessage(null);
    const submitData: ResourceRequestData = {
      request_type: data.request_type as ResourceType,
      notes: data.notes as string,
      documents: data?.documents?.map((type) => ({ type })) || undefined,
      profile_sections: data?.profile_sections?.map((type) => ({ type })) || undefined,
    };

    try {
      await initiateRequest({
        applicationId,
        data: submitData,
      }).unwrap();

      enqueueSnackbar(t('applications.api.initiate_resource_request.success'), {
        variant: 'success',
      });

      onCloseModal();
    } catch (e) {
      console.error(e);

      setErrorMessage(t('applications.api.initiate_resource_request.default_error'));

      enqueueSnackbar(t('applications.api.initiate_resource_request.default_error'), {
        variant: 'error',
      });
    }
  });

  const onCloseModal = () => {
    reset(defaultValues);
    onClose();
  };

  const validDocumentTypes = useMemo(() => (typeDefinitions?.DocumentType || []).filter((type) => ![DocumentType.SYSTEM_DOCUMENT, DocumentType.TERMS_OF_BUSINESS].includes(type as DocumentType)), [typeDefinitions]);

  const validSectionTypes = useMemo(() => (typeDefinitions?.CandidateProfileSectionType || []).filter((type) => type), [typeDefinitions]);

  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      sx={{ width: '100%', py: 2 }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('applications.resource_request_modal.title')}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
            {errorMessage}
          </Alert>
        )}
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={3} paddingX={2}>
            <Grid xs={12}>
              <Alert variant="standard" severity="info">
                {t('applications.resource_request_modal.alert')}
              </Alert>
            </Grid>
            <Grid xs={12}>
              <RHFSelect name="request_type" label="Request Type">
                {request_types.map((type) => (
                  <MenuItem key={type} value={type} disabled={type === ResourceType.Reference}>
                    <Stack
                      width="100%"
                      direction="row"
                      spacing={1}
                      display="flex"
                      justifyContent="space-between"
                    >
                      {t(`enums.resource_request_types.${type}`)}
                      {type === ResourceType.Reference && (
                        <Chip
                          label={t('common.coming_soon')}
                          color="error"
                          variant="filled"
                          size="small"
                        />
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {requestType === ResourceType.ProfileInformation && (
              <Grid xs={12}>
                <RHFMultiSelect
                  fullWidth
                  checkbox
                  name="profile_sections"
                  label={t('common.sections')}
                  options={
                    validSectionTypes.map((section: string) => ({
                      label: t(`enums.candidate_profile_section.${section}`),
                      value: section,
                    }))
                  }
                  disabled={isLoading}
                />
              </Grid>
            )}

            {requestType === ResourceType.Document && (
              <Grid xs={12}>
                <RHFMultiSelect
                  fullWidth
                  checkbox
                  name="documents"
                  label={t('common.documents')}
                  options={
                    validDocumentTypes.map((document: string) => ({
                      label: t(`enums.document_type.${document}`),
                      value: document,
                    }))
                  }
                  disabled={isLoading}
                />
              </Grid>
            )}

            <Grid xs={12}>
              <RHFTextField name="notes" label={t('common.notes')} multiline rows={4} />
            </Grid>

            <Grid xs={12}>
              <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
                {t('common.request')}
              </LoadingButton>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
