import { useSnackbar } from 'notistack';

import {
  Box,
  Grid
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';

import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';

export default function WorkStylesEditor() {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFMultiSelect
            fullWidth
            checkbox
            name="working_styles"
            label={t('enums.work_style.input_label')}
            options={
              typeDefinitionData
                ? typeDefinitionData.WorkStyleType.map((work_style: string) => ({
                  label: t(`enums.work_style.${work_style}`),
                  value: work_style,
                }))
                : []
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}