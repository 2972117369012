import { isString } from 'lodash';

import i18n from 'src/locales/i18n';

import { BusinessErrorCodes } from 'src/types/business-errors';

export const generateCandidateProfileErrorMessage = (error: any) => i18n.t('profile.api.update.error');

export const generateCompanyInviteErrorMessage = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.CompanyInviteExists) {
      return i18n.t('candidates.invites.company_invite_modal.api.invite_exists');
    }
    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyConnected) {
      return i18n.t('candidates.invites.company_invite_modal.api.already_connected');
    }
    if (e?.data.error_code === BusinessErrorCodes.InvalidCompanyInviteTarget) {
      return i18n.t('candidates.invites.company_invite_modal.api.invalid_target');
    }
    if (e?.data.error_code === BusinessErrorCodes.CandidateInviteExists) {
      return i18n.t('candidates.invites.company_invite_modal.api.candidate_invite_exists');
    }
    if (e?.data.error_code === BusinessErrorCodes.BadTenantOperation) {
      return i18n.t('candidates.invites.company_invite_modal.api.invalid_email_type');
    }
  }

  return i18n.t('candidates.invites.company_invite_modal.api.default_error');
}
