import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { toggleCompanyInviteModal } from 'src/store/slices/candidate/candidateSlice';
import { useCreateCompanyInviteMutation } from 'src/services/candidates/candidates.service';
import { generateCompanyInviteErrorMessage } from 'src/services/candidates/candidates.utils';

import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';

import { TenantType } from 'src/types/enums';

type CompanyInviteFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  previously_represented: boolean;
  position_title?: string;
  represented_date?: string;
};

type Props = {
  open: boolean;
  targetType: TenantType;
};

// custom validation for month/year format
const isValidMonthYearFormat = (value: string | undefined) => {
  if (!value) return true;
  const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;
  if (!regex.test(value)) return false;

  const [month, year] = value.split('/');
  const currentYear = new Date().getFullYear();
  const yearNum = parseInt(year, 10);

  return yearNum >= 1900 && yearNum <= currentYear;
};

export default function InviteCompanyConnectModal({ open, targetType }: Props) {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const [createCompanyInvite, { isLoading }] = useCreateCompanyInviteMutation();

  const CompanyInviteSchema = Yup.object().shape({
    first_name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email')).required(t('validation.required')),
    company_name: Yup.string().required(t('validation.required')),
    previously_represented: Yup.boolean().required(t('validation.required')),
    position_title: Yup.string().when('previously_represented', {
      is: true,
      then: (schema) => schema.required(t('validation.required')),
      otherwise: (schema) => schema.optional(),
    }),
    represented_date: Yup.string().when('previously_represented', {
      is: true,
      then: (schema) => schema
        .required(t('validation.required'))
        .test(
          'format',
          t('candidates.invites.company_invite_modal.form.represented_date.validation.date_format'),
          isValidMonthYearFormat
        ),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const methods = useForm<CompanyInviteFormValues>({
    resolver: yupResolver(CompanyInviteSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      previously_represented: false,
      position_title: '',
      represented_date: '',
    },
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const previouslyRepresented = watch('previously_represented');

  const handleClose = () => {
    reset();
    dispatch(toggleCompanyInviteModal({
      status: false
    }));
  };

  const handleMonthYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const cleaned = value.replace(/[^\d/]/g, '');

    let formatted = cleaned;
    if (cleaned.length >= 2 && !cleaned.includes('/')) {
      formatted = `${cleaned.slice(0, 2)  }/${  cleaned.slice(2)}`;
    }

    setValue('represented_date', formatted, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onFormSubmit = async (data: CompanyInviteFormValues) => {
    try {
      await createCompanyInvite({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        company_name: data.company_name,
        target_type: targetType,
        previously_represented: data.previously_represented,
        position_title: data.position_title,
        represented_date: data.represented_date,
      }).unwrap();

      enqueueSnackbar(t('candidates.invites.company_invite_modal.api.success'), { variant: 'success' });
      handleClose();
    } catch (error) {
      enqueueSnackbar(generateCompanyInviteErrorMessage(error), { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('candidates.invites.company_invite_modal.title')}
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <RHFTextField
                name="first_name"
                label={t('candidates.invites.company_invite_modal.form.first_name.label')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                name="last_name"
                label={t('candidates.invites.company_invite_modal.form.last_name.label')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                name="email"
                label={t('candidates.invites.company_invite_modal.form.email.label')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                name="company_name"
                label={t('candidates.invites.company_invite_modal.form.company_name.label')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {targetType === TenantType.Recruiter && (
              <>
                <Grid item xs={12}>
                  <RHFSwitch
                    name="previously_represented"
                    label={t('candidates.invites.company_invite_modal.form.previously_represented.label')}
                    sx={{ m: 0 }}
                  />
                </Grid>

                {previouslyRepresented && (
                  <>
                    <Grid item xs={12}>
                      <RHFTextField
                        name="position_title"
                        label={t('candidates.invites.company_invite_modal.form.position_title.label')}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <RHFTextField
                        name="represented_date"
                        label={t('candidates.invites.company_invite_modal.form.represented_date.label')}
                        placeholder="MM/YYYY"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleMonthYearChange}
                        inputProps={{
                          maxLength: 7,
                          pattern: "\\d{2}/\\d{4}"
                        }}
                        helperText={t('candidates.invites.company_invite_modal.form.represented_date.helper')}
                        error={!!errors.represented_date}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {t('common.submit')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}