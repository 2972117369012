import React, { useRef, useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Popover, Tooltip, MenuItem, useTheme, IconButton, FormControl, useMediaQuery } from '@mui/material';

import { useBoolean, BooleanManager } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { DocumentType } from 'src/services/files/file.types';

import Iconify from 'src/components/iconify';

type Props = {
  filters: {
    type?: string;
    search_query?: string;
  };
  onFilters: (type: string, value: string) => void;
  clearFilters: () => void;
  onOpenCreateForm: BooleanManager;
};

export default function DocumentsToolbar({
  filters = {},
  onFilters,
  clearFilters,
  onOpenCreateForm,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showFilterPopover = useBoolean();

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslate();

  const [selectedDocumentType, setSelectedDocumentType] = useState<string | null>(filters.type || null);
  const [searchValue, setSearchValue] = useState(filters.search_query || '');

  const onClearFilters = useCallback(() => {
    setSearchValue('');
    setSelectedDocumentType(null);
    clearFilters();
  }, [clearFilters]);

  const handleFilterType = useCallback(
    (type: string) => {
      setSelectedDocumentType(type);
      onFilters('type', type);
    },
    [onFilters]
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      onFilters('search_query', event.target.value);
    },
    [onFilters]
  );

  const disableReset = useMemo(() => !searchValue && !selectedDocumentType, [searchValue, selectedDocumentType]);

  const renderFilterContent = useMemo(() => (
    <>
      <TextField
        onChange={handleFilterName}
        placeholder="Search..."
        value={searchValue}
        name="description"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth sx={{}}>
        <TextField
          select
          label={t('common.placeholders.document_type')}
          value={selectedDocumentType || ''}
          onChange={(event) => handleFilterType(event.target.value as DocumentType)}
        >
          <MenuItem value="">{t('common.none')}</MenuItem>
          {Object.values(DocumentType).map((value) => (
            <MenuItem key={value} value={value}>
              {t(`documents.file_type.${value}`)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      {isMobile ? (
        <Button
          variant="text"
          disabled={disableReset}
          onClick={onClearFilters}
        >
          {t('common.clear')}
        </Button>
      ) : (
        <FormControl sx={{ width: 100 }}>
          <Tooltip title={t('common.clear')}>
            <IconButton
              size="large"
              disabled={disableReset}
              onClick={onClearFilters}
              aria-label={t('common.clear')}
            >
              <Iconify icon="material-symbols:cancel" />
            </IconButton>
          </Tooltip>
        </FormControl>
      )}
    </>
  ), [disableReset, handleFilterName, handleFilterType, onClearFilters, searchValue, selectedDocumentType, t, isMobile]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      {isMobile ? (
        <Box sx={{ position: 'relative' }}>
          <Button
            variant="text"
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={showFilterPopover.onTrue}
            ref={anchorRef}
          >
            {t('common.filter')}
          </Button>
          <Popover
            open={showFilterPopover.value}
            onClose={showFilterPopover.onFalse}
            anchorEl={anchorRef.current}
          >
            <Stack sx={{ p: 2, width: 280 }}>
              {renderFilterContent}
            </Stack>
          </Popover>
        </Box>
      ) : (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ flexGrow: 1, maxWidth: "75%" }}>
          {renderFilterContent}
        </Stack>
      )}
      <Button
        variant="contained"
        startIcon={<Iconify icon="eva:cloud-upload-fill" />}
        onClick={onOpenCreateForm.onTrue}
      >
        {t('common.upload')}
      </Button>
    </Stack>
  );
}