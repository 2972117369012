import { isString } from 'lodash';

import i18n from 'src/locales/i18n';

import { BusinessErrorCodes } from 'src/types/business-errors';

export const generateUploadError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.InvalidFileType) {
      return i18n.t('upload.api.create.invalid_file_type');
    }
  }
  return i18n.t('upload.api.create.default_error');
};
