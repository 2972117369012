import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Card, Link, CardContent, useMediaQuery } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate, fTime } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { openDocumentViewer } from 'src/store/slices/app/appSlice';
import { OrganisationDocument } from 'src/services/files/file.types';

import Iconify from 'src/components/iconify';
import FileThumbnail from 'src/components/file-thumbnail';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type Props = {
  row: OrganisationDocument;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow?: VoidFunction;
};

export default function FileManagerTableRow({ row, selected, onSelectRow, onDeleteRow }: Props) {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    name,
    type,
    file: { public_path, mime, created_at, custom_type_name, name: filename },
  } = row;

  const { t } = useTranslate();

  const details = useBoolean();

  const confirm = useBoolean();

  const popover = usePopover();

  const defaultStyles = {};

  const onDocumentOpen = (item: OrganisationDocument) => {
    dispatch(openDocumentViewer({
      title: filename as string,
      fileUrl: item.file.public_path
    }))
  }

  if (isMobile) {
    return (
      <Card
        sx={{
          m: 2,
          mb: 2,
          ...(selected && {
            boxShadow: theme.customShadows.z20,
            bgcolor: 'background.paper',
          }),
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FileThumbnail file={public_path} sx={{ width: 40, height: 40 }} />
              <Typography variant="subtitle1" noWrap>
                {name}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {t(`documents.file_type.${type}`)} {custom_type_name ? `(${custom_type_name})` : ''}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {fDate(created_at)}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Button
                LinkComponent={Link}
                href={public_path}
                target="_blank"
                variant="outlined"
                size="small"
              >
                {t('common.open')}
              </Button>
              <Button
                size="small"
                color="error"
                onClick={() => {
                  confirm.onTrue();
                }}
              >
                {t('common.delete')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <Button variant="contained" color="error" onClick={onDeleteRow}>
              {t('common.delete')}
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <>
      <TableRow
        selected={selected}
        sx={{
          borderRadius: 2,
          [`&.${tableRowClasses.selected}, &:hover`]: {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
            transition: theme.transitions.create(['background-color', 'box-shadow'], {
              duration: theme.transitions.duration.shortest,
            }),
            '&:hover': {
              backgroundColor: 'background.paper',
              boxShadow: theme.customShadows.z20,
            },
          },
          [`& .${tableCellClasses.root}`]: {
            ...defaultStyles,
          },
          ...(details.value && {
            [`& .${tableCellClasses.root}`]: {
              ...defaultStyles,
            },
          }),
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FileThumbnail file={public_path} sx={{ width: 36, height: 36 }} />

            <Typography
              noWrap
              variant="inherit"
              sx={{
                maxWidth: 360,
                cursor: 'pointer',
                ...(details.value && { fontWeight: 'fontWeightBold' }),
              }}
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {t(`documents.file_type.${type}`)} {custom_type_name ? `(${custom_type_name})` : ''}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={fDate(created_at)}
            secondary={fTime(created_at)}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          <Button variant="text" onClick={() => onDocumentOpen(row)}>
            {t('common.open')}
          </Button>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('common.delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
