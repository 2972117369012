import { debounce } from 'lodash';
import { useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import {
  Card,
  Badge,
  Stack,
  Button,
  Select,
  Tooltip,
  MenuItem,
  useTheme,
  CardProps,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { BooleanManager } from 'src/hooks/use-boolean';

import { TenantToColumnMap } from 'src/utils/jobs-kanban';

import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch } from 'src/store/store';
import { jobsApi } from 'src/services/jobs/jobs.service';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { JobStatus, JobFilters } from 'src/services/jobs/jobs.types';
import { showImportJobModal } from 'src/store/slices/jobs/jobsSlice';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { TenantType } from 'src/types/enums';

type Props = {
  showTable?: BooleanManager;
  filters: JobFilters;
  onFilters: (name: keyof JobFilters, value: any) => void;
  clearFilters: VoidFunction;
  showCreate?: boolean;
  showImport?: boolean;
} & CardProps;

export default function JobsTableToolbar({
  onFilters,
  showTable,
  filters,
  clearFilters,
  showCreate = true,
  showImport = true,
  sx,
}: Props) {
  const popover = usePopover();

  const settings = useSettingsContext();

  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const tenant = useOrgTenant();

  const dispatch = useAppDispatch();

  const [selectedJobStatus, setSelectedJobStatus] = useState<string>('');

  const [selectedStage, setSelectedStage] = useState<string>('');

  const [searchValue, setSearchValue] = useState('');

  const { isFetching /* fulfilledTimeStamp */ } = jobsApi.endpoints.getMyActiveJobs.useQueryState();

  const applicationStages = TenantToColumnMap[tenant];

  const onClearSearch = () => {
    setSearchValue('');

    onFilters('search_query', undefined);
  };

  const onClearFilters = () => {
    onClearSearch();

    setSelectedJobStatus('');
    setSelectedStage('');

    clearFilters();
    popover.onClose();
  };

  // eslint-disable-next-line
  const onSetSearchFilter = useCallback(
    debounce((value: string) => {
      onFilters('search_query', value !== '' ? value : undefined);
    }, 500),
    [onFilters]
  );

  const onSetSelectedJobStatus = useCallback(
    (jobStatus: string) => {
      setSelectedJobStatus(jobStatus);
      onFilters('job_status', jobStatus);
    },
    [onFilters]
  );

  const onSelectApplicationStage = useCallback(
    (stage: string) => {
      onFilters('application_status', stage);
      setSelectedStage(stage);
    },
    [onFilters]
  );

  const onSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetSearchFilter(event.target.value);
      setSearchValue(event.target.value);
    },
    [onSetSearchFilter]
  );

  const onImportJobClicked = useCallback(
    () => {
      dispatch(showImportJobModal());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const refreshData = () => {
    dispatch(rootApi.util.invalidateTags(['Job']));
  };

  const disableReset = useMemo(() => Object.values(filters).every((value) => !value), [filters]);

  // const lastUpdated = useMemo(
  //   () => (fulfilledTimeStamp ? new Date(fulfilledTimeStamp) : undefined),
  //   [fulfilledTimeStamp]
  // );

  const isHighContrast = settings.themeContrast === 'bold';

  const renderFilters = (
    <>
      <Box>
        <TextField
          value={searchValue}
          placeholder={t('jobs.toolbar.search.placeholder')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="bi:search" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClearSearch}>
                  <Iconify icon="iconamoon:close-bold" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ xs: { pl: 0 }, sm: { pl: 0 }, md: { pl: 1 }, minWidth: 120, maxWidth: 200 }}
          onChange={onSearch}
        />
      </Box>

      <Box>
        <FormControl fullWidth>
          <InputLabel id="job-status-select-label">
            {t('jobs.toolbar.filters.job_status')}
          </InputLabel>
          <Select
            id="job-status-select"
            value={selectedJobStatus}
            label="Job Status"
            sx={{
              minWidth: 150,
            }}
            onChange={(event) => onSetSelectedJobStatus(event.target.value as string)}
            placeholder="Job Status"
          >
            <MenuItem value="">{t('common.none')}</MenuItem>
            <MenuItem value={JobStatus.ACTIVE}>{t('enums.job_status.active')}</MenuItem>
            {tenant !== TenantType.Candidate && (
              <MenuItem value={JobStatus.DRAFT}>{t('enums.job_status.draft')}</MenuItem>
            )}
            {tenant !== TenantType.Candidate && (
              <MenuItem value={JobStatus.UNAPPROVED}>{t('enums.job_status.unapproved')}</MenuItem>
            )}
            <MenuItem value={JobStatus.ARCHIVED}>{t('enums.job_status.archived')}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box>
        <FormControl fullWidth>
          <InputLabel>{t('jobs.toolbar.filters.stage')}</InputLabel>
          <Select
            id="job-status-select"
            value={selectedStage}
            label="Job Status"
            sx={{
              minWidth: 150,
            }}
            onChange={(event) => onSelectApplicationStage(event.target.value as string)}
            placeholder="Job Status"
          >
            <MenuItem value="">{t('common.none')}</MenuItem>
            {applicationStages.map((stage) => (
              <MenuItem key={stage} value={stage}>
                {t(`enums.application_status.${stage}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Tooltip title={t('jobs.table.clear')}>
        <IconButton
          size="large"
          onClick={onClearFilters}
          aria-label="Clear job filters"
          disabled={disableReset}
        >
          <Iconify icon="material-symbols:cancel" />
        </IconButton>
      </Tooltip>
    </>
  );

  const filterCount = Object.values(filters).filter((value) => !!value).length;

  return (
    <Card
      sx={{
        ...sx,
        borderColor: !isHighContrast ? theme.palette.background.neutral : 'none',
        borderWidth: !isHighContrast ? 1 : 0,
        borderStyle: 'solid',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
      }}
      elevation={10}
    >
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 2 },
          width: '100%',
        }}
      >
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          justifyContent={{ xs: 'flex-end', lg: 'space-between' }}
          spacing={1}
          flexGrow={1}
        >
          <Stack
            direction="row"
            gap={1}
            sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}
          >
            {renderFilters}
          </Stack>

          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            flexWrap="wrap"
          >
            <Badge badgeContent={filterCount} color="error">
              <Button
                variant="text"
                startIcon={<Iconify icon="mdi:filter" />}
                sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' } }}
                onClick={popover.onOpen}
              >
                {t('common.filter')}
              </Button>
            </Badge>

            {tenant !== TenantType.Candidate && (
              <Tooltip title={`${t('jobs.table.refresh')}`}>
                <IconButton size="large" onClick={refreshData}>
                  <Iconify icon={isFetching ? 'eos-icons:bubble-loading' : 'mdi:reload'} />
                </IconButton>
              </Tooltip>
            )}

            {showTable && (
              <Tooltip
                title={
                  showTable.value ? t('jobs.table.toggle.kanban') : t('jobs.table.toggle.table')
                }
              >
                <span>
                  <IconButton size="large" onClick={showTable.onToggle}>
                    <Iconify icon={showTable.value ? 'bi:kanban-fill' : 'bi:grid-fill'} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Stack direction="row" gap={1}>
              {showCreate && (
                <Button
                  startIcon={<Iconify icon="solar:pen-bold" width={16} />}
                  variant="contained"
                  onClick={() => router.push(paths.dashboard.jobs.create)}
                  sx={{ maxHeight: 42, textWrap: 'nowrap' }}
                  id="create-job-button"
                >
                  {t('jobs.toolbar.add_job')}
                </Button>
              )}
              {showImport && (
                <Button
                  startIcon={<Iconify icon="solar:upload-bold" width={16} />}
                  variant="contained"
                  onClick={onImportJobClicked}
                  sx={{ maxHeight: 42, textWrap: 'nowrap' }}
                  id="import-job-button"
                >
                  {t('jobs.toolbar.import_job')}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top">
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} sx={{ p: 2 }}>
          {renderFilters}
        </Stack>
      </CustomPopover>
    </Card>
  );
}
