import _, { debounce } from 'lodash';
import { useMemo, useState, useEffect } from 'react';

import FileOpenIcon from '@mui/icons-material/FileOpen';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Card,
  Chip,
  Grid,
  List,
  Paper,
  Stack,
  Button,
  Dialog,
  Divider,
  ListItem,
  useTheme,
  TextField,
  IconButton,
  Pagination,
  Typography,
  CardContent,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  ListItemAvatar
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { DocumentType } from 'src/services/files/file.types';
import { CandidateProfileDocument } from 'src/services/candidates/candidates.types';
import { useGetProfileDocumentsQuery } from 'src/services/candidates/candidates.service';

import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

import UploadCandidateDocumentModal from 'src/sections/profile/modals/upload-document-modal';

type SteppedDocumentSelectorProps = {
  type: DocumentType;
  profileId: string;
  selectedDocument?: { profile_document_id: string; type: DocumentType };
  onSelect: (type: DocumentType, document: CandidateProfileDocument | null) => void;
};

const SEARCH_ITEMS_PER_PAGE = 4;

export function SteppedDocumentSelector({
  type,
  profileId,
  selectedDocument,
  onSelect,
}: SteppedDocumentSelectorProps) {

  const { t } = useTranslate();
  const theme = useTheme();
  const showUploadModal = useBoolean();
  const showSelector = useBoolean();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchPage, setSearchPage] = useState<number>(1);

  const { currentData: myDocuments } = useGetProfileDocumentsQuery({
    profileId,
    params: {
      page: searchPage,
      per_page: SEARCH_ITEMS_PER_PAGE,
      search_query: searchQuery,
      type,
    },
  });

  const [currentDocument, setCurrentDocument] = useState<CandidateProfileDocument | null>(null);

  useEffect(() => {
    const matchingDocument = myDocuments?.results.find((doc) => doc.id === selectedDocument?.profile_document_id);
    setCurrentDocument(selectedDocument?.type === type ? matchingDocument || null : null);
  }, [type, selectedDocument, myDocuments?.results]);

  const onDocumentAttached = (profileDoc: CandidateProfileDocument) => {
    setCurrentDocument(profileDoc);
    onSelect(type, profileDoc);
    showUploadModal.onFalse();
  };

  const handleRemoveDocument = () => {
    setCurrentDocument(null);
    onSelect(type, null);
  };

  const handleSelectDocument = (doc: CandidateProfileDocument | null) => {
    setCurrentDocument(doc);
    onSelect(type, doc);
    showSelector.onFalse();
  };

  // debounced search query
  const handleSearch = useMemo(
    () => debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    []
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={3}>
          {/* Header Section */}
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <InsertDriveFileIcon color="primary" />
              <Typography variant="h6">
                {t(`enums.document_type.${type}`)}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {t('applications.resource_requests.action.document_instruction')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Selected Document Display */}
          {currentDocument ? (
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  bgcolor: theme.palette.background.neutral,
                  border: `1px solid ${theme.palette.divider}`
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FileOpenIcon color="primary" />
                    <Box>
                      <TruncatedTextField
                        text={currentDocument.document.name}
                        limit={30}
                        mode='characters'
                        showMoreEnabled={false}
                        variant="subtitle1"
                      />
                    </Box>
                    <Chip
                      label={t(`enums.document_type.${currentDocument.document.type}`)}
                      size="small"
                      variant='outlined'
                      color="info"
                    />
                  </Stack>
                  <IconButton onClick={handleRemoveDocument} color="error">
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
              </Paper>
            </Grid>
          ) : null}

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent={currentDocument ? 'flex-end' : 'center'}
            >
              {!currentDocument && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    onClick={showUploadModal.onTrue}
                  >
                    {t('common.upload')} {t('common.new')}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<FileOpenIcon />}
                    onClick={showSelector.onTrue}
                  >
                    {t('applications.resource_requests.action.select_existing')}
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>

      {/* Document Selection Dialog */}
      <Dialog
        open={showSelector.value}
        onClose={showSelector.onFalse}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t('applications.resource_requests.action.select_document')}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 400, display: 'flex', flexDirection: 'column' }}>
          <TextField
            fullWidth
            placeholder={t('common.search')}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Stack direction="column" justifyContent="space-between" spacing={2} flexGrow={1}>
            <List>
              {(myDocuments?.results || []).map((doc) => (
                <ListItem
                  key={doc.id}
                  secondaryAction={
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleSelectDocument(doc)}
                    >
                      {t('common.select')}
                    </Button>
                  }
                >
                  <ListItemAvatar>
                    <InsertDriveFileIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <TruncatedTextField
                        text={doc.document.name}
                        limit={40}
                        mode='characters'
                        showMoreEnabled={false}
                        variant="subtitle2"
                      />
                    }
                    secondary={_.startCase(doc.document.type)}
                  />
                </ListItem>
              ))}
            </List>

            <Stack direction="row" justifyContent="center" spacing={2}>
              <Pagination
                count={Math.ceil((myDocuments?.count || 1) / SEARCH_ITEMS_PER_PAGE)}
                page={searchPage}
                onChange={(e, page) => setSearchPage(page)}
                sx={{ mt: 2, justifyContent: 'center' }}
              />
            </Stack>
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button onClick={showSelector.onFalse}>
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Modal */}
      {showUploadModal.value && (
        <UploadCandidateDocumentModal
          open={showUploadModal.value}
          onClose={showUploadModal.onFalse}
          profileId={profileId}
          type={type}
          onUpload={onDocumentAttached}
        />
      )}
    </Card>
  );
}