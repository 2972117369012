import { useSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import {
  Box,
  Tab,
  Tabs,
  Badge,
  Button,
  Divider,
  Tooltip,
  MenuItem,
  Skeleton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';
import { useParams, useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  ClientApplicationStages,
  ApplicationStageDivergence,
  RecruiterApplicationStages,
} from 'src/utils/applications-kanban';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useAuth } from 'src/auth/hooks/useAuth';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { JobVerificationStatus } from 'src/services/jobs/jobs.types';
import { openInterviewSchedulerModal } from 'src/store/slices/interviews/interviewSlice';
import {
  setShowOfferModal,
  setShowActionStageRequestModal,
} from 'src/store/slices/applications/applicationsSlice';
import {
  ApplicationStage,
  WithdrawalContext,
  StageChangeRequestStatus,
} from 'src/services/applications/applications.types';
import {
  isApplicationFinalized,
  getUpdateStageErrorMessage,
  getConfirmStageChangeContentMessage,
  getConfirmStageChangeRequestContentMessage,
} from 'src/services/applications/applications.utils';
import {
  useGetApplicationByIdQuery,
  useGetApplicationOffersQuery,
  useUpdateApplicationRatingMutation,
  useProgressCandidateApplicationMutation,
  useRequestApplicationStageChangeMutation,
  useGetApplicationStageChangeRequestsQuery,
  useGetOutgoingApplicationResourceRequestsQuery,
} from 'src/services/applications/applications.service';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import StarRating from 'src/components/rating/star-rating';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover from 'src/components/custom-popover/custom-popover';

import CandidateProfileDocuments from 'src/sections/profile/documents/profile-documents';
import WithdrawalContextAlert from 'src/sections/jobs/applications/alerts/withdrawal-alert';
import ProfileImporterDialog from 'src/sections/jobs/applications/modals/candidate-profile-importer';
import ApplicationComplianceSubmissionView from 'src/sections/jobs/applications/detail/compliance-submissions-view';

import { TenantType } from 'src/types/enums';

import OffersListModal from '../modals/offer/offers-list-modal';
import InterviewListModal from '../modals/interview/interview-list-modal';
import ApplicationCandidateProfile from './application-candidate-profile';
import ResourceRequestModal from '../modals/resource-requests/resource-request-modal';
import ResourceRequestsListModal from '../modals/resource-requests/resource-requests-list-modal';

enum TabValue {
  PROFILE = 'profile',
  DOCUMENTS = 'documents',
  COMPLIANCE_SUBMISSIONS = 'compliance_submissions',
}

export default function ApplicationDetailView() {

  const { application_id, job_id } = useParams();

  const { t } = useTranslate();

  const { context } = useAuth();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const stagePopover = usePopover();

  const showActions = usePopover();

  const profileImporterControl = useBoolean();

  const confirmStageChange = useBoolean();

  const confirmRequestStageChange = useBoolean();

  const showResourceRequests = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const showOffersModal = useBoolean();

  const showOutgoingResourceRequests = useBoolean();

  const showInterviewListModal = useBoolean();

  const dispatch = useAppDispatch();

  const router = useRouter();

  const [resourceRequestPageOptions, setResourceRequestPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5,
  });

  const [currentTab, setCurrentTab] = useState<TabValue>(TabValue.PROFILE);

  const [requestedStage, setRequestedStage] = useState<ApplicationStage | null>(null);

  const { currentData: resourceRequests } = useGetOutgoingApplicationResourceRequestsQuery(
    {
      applicationId: application_id as string,
      params: {
        ...resourceRequestPageOptions,
      },
    },
    { skip: !application_id }
  );

  const [updateApplicationRating, { isLoading: updatingRating }] = useUpdateApplicationRatingMutation();

  const onChangeResourceRequestPage = useCallback((e: any, value: number) => {
    setResourceRequestPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const { currentData: application, isLoading } = useGetApplicationByIdQuery(
    application_id as string,
    {
      skip: !application_id,
    }
  );

  const { currentData: offers, isLoading: isLoadingOffers } = useGetApplicationOffersQuery(
    {
      applicationId: application_id as string,
      params: { page: 1, per_page: 1 },
    },
    { skip: !application_id }
  );

  const { currentData: stageChangeRequests, isLoading: isLoadingRequests } =
    useGetApplicationStageChangeRequestsQuery(
      {
        applicationId: application_id as string,
        params: { page: 1, per_page: 1, status: StageChangeRequestStatus.PENDING },
      },
      { skip: !application_id }
    );

  const mustRequestStageChange = useMemo(
    () => application?.manager_type === TenantType.Recruiter && tenantType === TenantType.Client,
    [application?.manager_type, tenantType]
  );

  const canScheduleInterview = tenantType !== TenantType.Candidate;

  // useMemo(
  //   () =>
  //     tenantType !== TenantType.Candidate
  //     // application &&
  //     // [ApplicationStage.INTERVIEW, ApplicationStage.INTERNAL_INTERVIEW].includes(
  //     //   application.stage as ApplicationStage
  //     // ),
  //   [tenantType]
  // );

  const onBack = useCallback(() => {
    router.back();
  }, [router]);

  const [progressApplication, { isLoading: isProgressing }] =
    useProgressCandidateApplicationMutation();

  const [requestStageChange, { isLoading: isRequesting }] =
    useRequestApplicationStageChangeMutation();

  const handleChangeTab = (_event: ChangeEvent<{}>, newValue: TabValue) => {
    setCurrentTab(newValue);
  };

  const onOpenRequestModal = useCallback(() => {
    dispatch(setShowActionStageRequestModal(stageChangeRequests?.results[0].id as string));
  }, [dispatch, stageChangeRequests?.results]);

  const onExtendOffer = useCallback(() => {
    dispatch(setShowOfferModal({ applicationId: application_id as string }));
  }, [application_id, dispatch]);

  const onOpenInterviewModal = useCallback(() => {
    dispatch(openInterviewSchedulerModal({ applicationId: application_id as string }));
  }, [application_id, dispatch]);

  const stageDisabled = (itemStage: ApplicationStage, currentApplicationStage: ApplicationStage) =>
    RecruiterApplicationStages.findIndex((column) => column === itemStage) <
    RecruiterApplicationStages.findIndex((column) => column === currentApplicationStage) ||
    itemStage === currentApplicationStage ||
    (ClientApplicationStages.includes(currentApplicationStage) &&
      ApplicationStageDivergence.includes(itemStage));

  const onProgressApplication = useCallback(
    async (stage: ApplicationStage) => {
      stagePopover.onClose();

      confirmStageChange.onFalse();

      try {
        await progressApplication({
          applicationId: application_id as string,
          data: { stage: stage as ApplicationStage },
        }).unwrap();

        enqueueSnackbar(t('applications.api.update_stage.success'));
      } catch (e) {
        console.error(e);

        enqueueSnackbar(getUpdateStageErrorMessage(e), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progressApplication]
  );

  const onRequestProgressApplication = useCallback(
    async (stage: ApplicationStage) => {
      confirmRequestStageChange.onFalse();
      try {
        await requestStageChange({
          applicationId: application_id as string,
          requested_stage: stage,
        }).unwrap();

        enqueueSnackbar(t('applications.api.update_stage.success'));
      } catch (e) {
        console.error(e);

        enqueueSnackbar(getUpdateStageErrorMessage(e), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestStageChange]
  );

  const renderHead = (
    <>
      <Grid xs={12} sm={12} md={12} lg={4}>
        <Stack direction="column" gap={1}>
          <Box>
            <Button
              fullWidth={false}
              component={RouterLink}
              onClick={onBack}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
              {t('common.back')}
            </Button>
          </Box>

          {isLoading ? (
            <Skeleton variant="text" width={400} height={60} />
          ) : (
            <Typography variant="h3" flexWrap="nowrap" sx={{ textTransform: 'capitalize' }}>
              {`${application?.attached_profile.first_name} ${application?.attached_profile.last_name}`}
            </Typography>
          )}

          {/* <Typography variant="subtitle1" color={theme.palette.grey[600]}>
            placeholder@gmail.com
          </Typography> */}
        </Stack>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={8}
        display="flex"
        alignItems="flex-end"
        justifyContent={{ md: 'space-between', lg: 'flex-end' }}
      >
        {application?.stage && (
          <Box>
            <Stack
              direction="row"
              height="100%"
              spacing={1}
              alignItems="flex-end"
              justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
            >

              {
                application?.sent_to_client_at === null && (
                  <Button startIcon={<Iconify icon="solar:upload-bold" />} variant="contained" onClick={profileImporterControl.onTrue}>
                    {t('applications.detail.actions.import_profile')}
                  </Button>
                )
              }

              <LoadingButton
                disabled={isLoadingRequests || isLoading}
                loading={isProgressing || isRequesting}
                onClick={showActions.onOpen}
                variant="contained"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  {`${t('common.job')} ${t('common.actions')}`}
                  <Iconify sx={{ display: 'inline-block' }} icon="eva:arrow-ios-downward-fill" />
                </Stack>
              </LoadingButton>

              <CustomPopover
                open={showActions.open}
                onClose={showActions.onClose}
                arrow="top-right"
              >
                {tenantType !== TenantType.Candidate && (
                  <MenuItem
                    disabled={isLoading || isApplicationFinalized(application?.stage)}
                    onClick={showResourceRequests.onTrue}
                  >
                    {t('applications.detail.actions.request_information')}
                  </MenuItem>
                )}

                <Badge badgeContent={resourceRequests?.count} color="error">
                  <MenuItem
                    onClick={() => {
                      showOutgoingResourceRequests.onTrue();
                      showActions.onClose();
                    }}
                    disabled={!resourceRequests?.count}
                  >
                    {t('applications.detail.actions.view_resource_requests')}
                  </MenuItem>
                </Badge>

                {tenantType !== TenantType.Candidate && (
                  <Tooltip title={t('applications.detail.actions.offer_tooltip')}>
                    <span>
                      <MenuItem
                        onClick={onExtendOffer}
                        disabled={Boolean(
                          (tenantType === TenantType.Recruiter &&
                            application?.job.verification_status ===
                            JobVerificationStatus.VERIFIED) ||
                          isApplicationFinalized(application?.stage)
                        )}
                      >
                        {t('applications.detail.actions.offer')}
                      </MenuItem>
                    </span>
                  </Tooltip>
                )}

                <MenuItem
                  disabled={isLoadingOffers || !offers?.count}
                  onClick={() => {
                    showOffersModal.onTrue();
                    showActions.onClose();
                  }}
                >
                  {t('applications.detail.actions.view_offers')}
                </MenuItem>

                {tenantType === TenantType.Recruiter && (
                  <Badge badgeContent={stageChangeRequests?.count} color="error">
                    <MenuItem
                      disabled={!stageChangeRequests?.count || isLoadingRequests}
                      onClick={() => {
                        onOpenRequestModal();
                        showActions.onClose();
                      }}
                    >
                      {t('applications.detail.actions.manage_requests')}
                    </MenuItem>
                  </Badge>
                )}

                <MenuItem
                  disabled={isLoading || !canScheduleInterview || isApplicationFinalized(application?.stage)}
                  onClick={onOpenInterviewModal}
                >
                  {t('applications.detail.actions.schedule_interview')}
                </MenuItem>

                <MenuItem disabled={isLoading} onClick={showInterviewListModal.onTrue}>
                  {t('applications.detail.actions.view_interviews')}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setRequestedStage(ApplicationStage.SHORTLISTED);
                    confirmStageChange.onTrue();
                    showActions.onClose();
                  }}
                  disabled={isLoading || isApplicationFinalized(application?.stage)}
                >
                  {t('applications.detail.actions.shortlist')}
                </MenuItem>

                {!isApplicationFinalized(application?.stage) && (
                  <MenuItem
                    onClick={() => {
                      setRequestedStage(ApplicationStage.TO_BE_REJECTED);
                      confirmStageChange.onTrue();
                      showActions.onClose();
                    }}
                    disabled={
                      isLoading ||
                      !application ||
                      [
                        ApplicationStage.REJECTED,
                        ApplicationStage.WITHDRAWN,
                        ApplicationStage.TO_BE_REJECTED,
                      ].includes(application.stage)
                    }
                  >
                    {t('applications.detail.actions.reject')}
                  </MenuItem>
                )}
              </CustomPopover>

              <Tooltip
                title={
                  application.requested_stage ? t('applications.detail.kanban.request_tooltip') : ''
                }
              >
                <span>
                  <LoadingButton
                    loading={isProgressing || isRequesting}
                    disabled={
                      isLoading || !application_id || !application || application.requested_stage
                    }
                    variant="contained"
                    onClick={stagePopover.onOpen}
                    endIcon={
                      <Iconify
                        sx={{ display: 'inline-block' }}
                        icon="eva:arrow-ios-downward-fill"
                      />
                    }
                  >
                    {`${t('common.stage')}: ${t(
                      `enums.application_status.${application?.requested_stage ?? application?.stage
                      }`
                    )}`}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          </Box>
        )}
      </Grid>
    </>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab} sx={{ pl: 2 }}>
      <Tab
        key={TabValue.PROFILE}
        iconPosition="end"
        value={TabValue.PROFILE}
        label={t('applications.detail.tabs.profile')}
      />
      <Tab
        key={TabValue.DOCUMENTS}
        value={TabValue.DOCUMENTS}
        label={t('applications.detail.tabs.documents')}
      />

      {
        application?.manager?.id === context?.organization?.id && (
          <Tab
            key={TabValue.COMPLIANCE_SUBMISSIONS}
            value={TabValue.COMPLIANCE_SUBMISSIONS}
            label={t('applications.detail.tabs.compliance_submissions')}
          />
        )
      }

    </Tabs>
  );

  const getClientThread = () => {
    if (application?.manager_client_thread) {
      if (
        (tenantType === application.manager_type &&
          application.manager_type === TenantType.Recruiter) ||
        tenantType === TenantType.Client
      ) {
        return application.manager_client_thread.id;
      }
    }

    return undefined;
  };

  const getCandidateThread = () => {
    if (application?.manager_candidate_thread) {
      if (tenantType === application.manager_type || tenantType === TenantType.Candidate) {
        return application.manager_candidate_thread.id;
      }
    }

    return undefined;
  };

  const handleRatingChange = useCallback(async (id: string, rating: number) => {
    try {
      await updateApplicationRating({ applicationId: id, rating }).unwrap();

      enqueueSnackbar(t('applications.api.update_rating.success'), { variant: 'success' });
    }
    catch (error) {
      console.error(error);

      enqueueSnackbar(t('applications.api.update_rating.default_error'), { variant: 'error' });
    }
  }, [updateApplicationRating, t, enqueueSnackbar]);

  return (
    <>
      <Grid container spacing={3}>
        {renderHead}

        <Grid xs={12}>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'center', sm: "space-between" }} alignItems={{ xs: 'start', sm: "center" }} spacing={2} pt={2}>
            {renderTabs}

            {
              !isMobile &&
              <Stack sx={{ px: 2 }} direction="column" alignItems="end" justifyContent="end" gap={.5}>
                <Typography variant='caption' color='text.disabled'>{t('applications.detail.rating')}</Typography>
                <StarRating rating={application?.rating as number} onSelectExcitementRating={(rating) => handleRatingChange(application?.id as string, rating)} />
              </Stack>
            }

          </Stack>
        </Grid>

        {application?.stage === ApplicationStage.WITHDRAWN && application?.withdrawal_context && (
          <Grid xs={12}>
            <WithdrawalContextAlert
              context={application?.withdrawal_context as WithdrawalContext}
            />
          </Grid>
        )}

        <Grid xs={12}>
          {isLoading ? (
            <Box paddingTop={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {application ? (
                <>
                  {currentTab === TabValue.PROFILE && (
                    <ApplicationCandidateProfile
                      application={application}
                      profileId={application.attached_profile.id as string}
                      managerCandidateThreadId={getCandidateThread()}
                      managerClientThreadId={getClientThread()}
                      internalThreadId={application.internal_thread?.id}
                    />
                  )}

                  {currentTab === TabValue.DOCUMENTS && (
                    <CandidateProfileDocuments profileId={application.attached_profile.id} />
                  )}

                  {currentTab === TabValue.COMPLIANCE_SUBMISSIONS && (
                    <ApplicationComplianceSubmissionView
                        applicationId={application_id as string}
                        jobId={job_id as string}
                      />
                  )}

                </>
              ) : (
                <Box paddingTop={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="subtitle2" color={theme.palette.grey[600]}>
                    {t('applications.detail.candidate_profile.failed')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid >

      <CustomPopover open={stagePopover.open} onClose={stagePopover.onClose}>
        <Stack spacing={1}>
          {RecruiterApplicationStages.filter((stage) => stage !== ApplicationStage.WITHDRAWN).map((stage) => (
            <MenuItem
              key={stage}
              onClick={() => {
                setRequestedStage(stage as ApplicationStage);

                if (mustRequestStageChange) {
                  confirmRequestStageChange.onTrue();
                  return;
                }
                confirmStageChange.onTrue();
              }}
              disabled={stageDisabled(
                stage,
                application?.requested_stage ?? (application?.stage as ApplicationStage)
              )}
            >
              {t(`enums.application_status.${stage}`)}
            </MenuItem>
          ))}
        </Stack>
      </CustomPopover>

      <ConfirmDialog
        open={confirmRequestStageChange.value}
        onClose={confirmRequestStageChange.onFalse}
        title={t('common.confirm')}
        content={getConfirmStageChangeRequestContentMessage(requestedStage as ApplicationStage)}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => onRequestProgressApplication(requestedStage as ApplicationStage)}
          >
            {t('common.confirm')}
          </Button>
        }
      />

      <ConfirmDialog
        open={confirmStageChange.value}
        onClose={confirmStageChange.onFalse}
        title={t('common.confirm')}
        content={getConfirmStageChangeContentMessage(
          requestedStage as ApplicationStage,
          tenantType
        )}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => onProgressApplication(requestedStage as ApplicationStage)}
          >
            {t('common.confirm')}
          </Button>
        }
      />

      <OffersListModal
        open={showOffersModal.value}
        applicationId={application_id as string}
        onClose={showOffersModal.onFalse}
      />

      {
        application_id && (
          <ResourceRequestModal
            open={showResourceRequests.value}
            onClose={showResourceRequests.onFalse}
            applicationId={application_id as string}
          />
        )
      }

      {
        application_id && application && (
          <ResourceRequestsListModal
            open={showOutgoingResourceRequests.value}
            onClose={showOutgoingResourceRequests.onFalse}
            target="outgoing"
            requests={resourceRequests}
            pageOptions={resourceRequestPageOptions}
            onChangePage={onChangeResourceRequestPage}
            profileId={application.attached_profile.id as string}
          />
        )
      }

      <ProfileImporterDialog open={profileImporterControl.value} onClose={profileImporterControl.onFalse} applicationId={application?.id as string} />

      {
        application_id && (
          <InterviewListModal
            applicationId={application_id as string}
            open={showInterviewListModal.value}
            onClose={showInterviewListModal.onFalse}
          />
        )
      }

    </>
  );
}
