import _ from 'lodash';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Tab, Card, List, Tabs, Alert, Stack, Avatar, Button, Dialog, Divider, Tooltip, ListItem, IconButton, Pagination, Typography, CardContent, DialogTitle, ListItemText, DialogActions, DialogContent, ListItemAvatar } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { IncomingCompanyInvite, OutgoingCandidateInvite } from 'src/services/candidates/candidates.types';
import { toggleCandidateInviteModal, toggleCompanyInviteListModal } from 'src/store/slices/candidate/candidateSlice';
import {
  useActionCompanyInviteMutation,
  useGetIncomingCompanyInvitesQuery,
  useCancelOrgCandidateInviteMutation,
  useGetOutgoingOrgCandidateInvitesQuery
} from 'src/services/candidates/candidates.service';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import DateDisplay from 'src/components/date/date-display';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { TenantType } from 'src/types/enums';

type PaginationControlProps = {
  page: number;
  perPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
};

const PaginationControl = ({ page, perPage, totalCount, onPageChange }: PaginationControlProps) => (
  <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
    <Pagination
      page={page || 1}
      count={Math.ceil((totalCount || 1) / perPage)}
      onChange={(_e, value) => onPageChange(value)}
    />
  </Stack>
);


type CandidateInviteListItemProps = {
  invite: OutgoingCandidateInvite;
  onCancel: (invite: OutgoingCandidateInvite) => void;
};

type CompanyInviteListItemProps = {
  invite: IncomingCompanyInvite;
  onManage: (invite: IncomingCompanyInvite) => void;
};

const CandidateInviteListItem = ({ invite, onCancel }: CandidateInviteListItemProps) => (
  <>
    <ListItem sx={{ mb: 1 }} secondaryAction={
      <IconButton
        edge="end"
        aria-label="cancel"
        onClick={() => onCancel(invite)}
        sx={{ color: 'error.main' }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    }>
      <ListItemAvatar>
        <Avatar>{invite.first_name?.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{
          paddingRight: 14,
        }}
        primary={`${invite.first_name} ${invite.last_name}`}
        secondary={
          <Stack direction="row" alignContent="center" justifyContent="start" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              {invite.email}
            </Typography>

            <DateDisplay prefix={t('candidates.invites.sent_on')} variant="caption" date={invite.created_at} format='DD/MM/YYYY' />
          </Stack>
        }
      />
    </ListItem>
    <Divider variant="middle" component="li" />
  </>
);

const CompanyInviteListItem = ({ invite, onManage }: CompanyInviteListItemProps) => (
  <>
    <ListItem sx={{ mb: 1 }} secondaryAction={
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        aria-label="manage"
        onClick={() => onManage(invite)}
      >
        {t('common.manage')}
      </Button>
    }>
      <ListItemAvatar>
        <Avatar>{invite.candidate_profile.first_name?.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{
          paddingRight: 14,
        }}
        primary={_.capitalize(`${invite.candidate_profile.first_name} ${invite.candidate_profile.last_name}`)}
        secondary={invite.candidate_profile.position_title}
      />
    </ListItem>
    <Divider variant="middle" component="li" />
  </>
);

type CandidateInviteDetailProps = {
  invite: OutgoingCandidateInvite;
};

const CandidateInviteDetail = ({ invite }: CandidateInviteDetailProps) => (
  <Card sx={{ boxShadow: 'unset' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ mr: 2 }}>{invite.first_name.charAt(0)}</Avatar>
        <Stack direction="column">
          <Typography variant="h6">{invite.first_name}</Typography>
          <Typography variant="body1" color="text.secondary" textTransform="capitalize">
            {invite.email}
          </Typography>
        </Stack>
      </Box>
    </CardContent>
  </Card>
);

type CompanyInviteDetailProps = {
  invite: IncomingCompanyInvite;
};

const CompanyInviteDetail = ({ invite }: CompanyInviteDetailProps) => (
  <Card sx={{ boxShadow: 'unset' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ mr: 2 }}>{invite.candidate_profile.first_name.charAt(0)}</Avatar>
        <Stack direction="column">
          <Typography variant="h6">{_.capitalize(`${invite.candidate_profile.first_name} ${invite.candidate_profile.last_name}`)}</Typography>
          <Typography variant="body2">{invite.candidate_profile.position_title}</Typography>
        </Stack>
      </Box>
      <Stack>
        <Alert variant="outlined" severity="info" sx={{ mb: 1 }}>
          <Trans>
            {
              invite.previously_represented ?
                t('candidates.invites.company_invites_modal.action.alert_previously_represented', {
                  position_title: invite.position_title,
                  represented_date: invite.represented_date,
                })
                :
                t('candidates.invites.company_invites_modal.action.alert_not_represented')
            }
          </Trans>
        </Alert>
      </Stack>
    </CardContent>
  </Card>
);

type Props = {
  open: boolean;
  defaultTab?: TabValue;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

enum TabValue {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export default function CompanyInvitesListModal({ open, defaultTab = TabValue.Incoming }: Props) {

  const dispatch = useAppDispatch();
  const tenant = useOrgTenant();
  const { enqueueSnackbar } = useSnackbar();

  const cancelInviteConfirm = useBoolean();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tab, setTab] = useState<TabValue>(defaultTab);
  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [view, setView] = useState<'invite_list' | 'invite_manager'>('invite_list');

  const [selectedCandidateInvite, setSelectedCandidateInvite] = useState<OutgoingCandidateInvite | null>(null);
  const [selectedCompanyInvite, setSelectedCompanyInvite] = useState<IncomingCompanyInvite | null>(null);

  const { currentData: incomingCompanyInvites } = useGetIncomingCompanyInvitesQuery(pageOptions, {
    skip: tenant === TenantType.Candidate,
  });

  const { currentData: outgoingCandidateInvites } = useGetOutgoingOrgCandidateInvitesQuery(pageOptions, {
    skip: tenant === TenantType.Candidate,
  });

  const [actionCompanyInvite, { isLoading: isCompanyInviteActionLoading }] = useActionCompanyInviteMutation();
  const [cancelCandidateInvite, { isLoading: isCancellingCandidateInvite }] = useCancelOrgCandidateInviteMutation();

  const onClose = useCallback(() => {

    dispatch(toggleCompanyInviteListModal({
      status: false
    }));

    setView('invite_list');
    setSelectedCompanyInvite(null);
  }, [dispatch]);

  const changePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const changeTab = useCallback((event: React.SyntheticEvent, newValue: TabValue) => {
    setPageOptions(DEFAULT_PAGE);
    setTab(newValue);
  }, []);

  const onManageRequest = useCallback((invite: IncomingCompanyInvite) => {
    setSelectedCompanyInvite(invite);
    setView('invite_manager');
  }, []);

  const onBack = useCallback(() => {
    setView('invite_list');
    setSelectedCandidateInvite(null);
    setSelectedCompanyInvite(null);
  }, []);

  const handleAction = async (invite: IncomingCompanyInvite, action: 'accept' | 'decline') => {
    setErrorMessage(null);
    try {
      await actionCompanyInvite({ inviteId: invite.id, action }).unwrap();
      enqueueSnackbar(t(`candidates.invites.candidate_invites_modal.api.success_${action}`), { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.api.default_error'), { variant: 'error' });
    }
  };

  const onCancelCandidateInvite = (invite: OutgoingCandidateInvite) => {
    setSelectedCandidateInvite(invite);
    cancelInviteConfirm.onTrue();
  }

  const handleCloseCancelInviteConfirm = () => {
    cancelInviteConfirm.onFalse();
    setSelectedCandidateInvite(null);
  }

  const handleCancelCandidateInvite = useCallback(async () => {

    if (!selectedCandidateInvite) return;

    try {
      cancelInviteConfirm.onFalse();
      await cancelCandidateInvite({
        inviteId: selectedCandidateInvite?.id as string,
      }).unwrap();
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.company_invites.api.cancel.success'), { variant: 'success' });

      setSelectedCandidateInvite(null);

    } catch (error) {
      console.log(error)
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.company_invites.api.cancel.default_error'), { variant: 'error' });
    }
  }, [selectedCandidateInvite, cancelCandidateInvite, enqueueSnackbar, cancelInviteConfirm]);

  const onAddNewInvite = useCallback(() => {
    dispatch(toggleCandidateInviteModal({
      status: true
    }));
  }, [dispatch]);

  const renderInviteList = () => (
    <Stack direction="column">
      {errorMessage ? (
        <Alert variant="outlined" severity="error" sx={{ mb: 1 }}>
          {errorMessage}
        </Alert>
      ) : (
        <Alert variant="outlined" severity="info" sx={{ mb: 1 }}>
          {t('candidates.invites.company_invites_modal.alert')}
        </Alert>
      )}

      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Tabs
          value={tab}
          onChange={changeTab}
          aria-label="Connection invite navigation tabs"
          role="navigation"
        >
          <Tab label={t('common.incoming')} value={TabValue.Incoming} />
          <Tab label={t('common.outgoing')} value={TabValue.Outgoing} />
        </Tabs>

        {
          tab === TabValue.Outgoing && (
            <IconButton
              onClick={onAddNewInvite}
              sx={{ color: 'primary.main', mt: 1 }}
              aria-label="add-invite"
            >
              <Tooltip title={t('candidates.invites.tooltips.new_invite')}>
                <Iconify icon="solar:add-circle-bold" />
              </Tooltip>
            </IconButton>
          )
        }

      </Stack>

      {tab === TabValue.Incoming && (
        <>
          {incomingCompanyInvites?.count ? (
            <List dense>
              {incomingCompanyInvites?.results?.map((invite, index) => (
                <CompanyInviteListItem
                  key={index}
                  invite={invite}
                  onManage={onManageRequest}
                />
              ))}
            </List>
          ) : (
            <EmptyContent filled title="No Data" sx={{ py: 10 }} />
          )}
          <PaginationControl
            page={pageOptions.page}
            perPage={pageOptions.per_page}
            totalCount={incomingCompanyInvites?.count || 0}
            onPageChange={changePage}
          />
        </>
      )}

      {tab === TabValue.Outgoing && (
        <>
          {outgoingCandidateInvites?.count ? (
            <List dense>
              {outgoingCandidateInvites?.results?.map((invite, index) => (
                <CandidateInviteListItem
                  key={index}
                  invite={invite}
                  onCancel={onCancelCandidateInvite}
                />
              ))}
            </List>
          ) : (
            <EmptyContent filled title="No Data" sx={{ py: 10 }} />
          )}
          <PaginationControl
            page={pageOptions.page}
            perPage={pageOptions.per_page}
            totalCount={outgoingCandidateInvites?.count || 0}
            onPageChange={changePage}
          />

          <ConfirmDialog
            open={cancelInviteConfirm.value}
            onClose={handleCloseCancelInviteConfirm}
            title={t('common.cancel')}
            content={t('candidates.invites.candidate_invites_modal.company_invites.cancel_confirm')}
            action={
              <Button variant="contained" color="error" onClick={handleCancelCandidateInvite}>
                {t('common.yes')}
              </Button>
            }
          />

        </>
      )}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t('candidates.invites.company_invites_modal.title', {
          target: t(tab === TabValue.Incoming ? 'common.incoming' : 'common.outgoing'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {view === 'invite_list' ? (
            renderInviteList()
          ) : (
            <>
              {selectedCandidateInvite && <CandidateInviteDetail invite={selectedCandidateInvite} />}
              {selectedCompanyInvite && <CompanyInviteDetail invite={selectedCompanyInvite} />}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {view === 'invite_list' && (
          <Button onClick={onClose}>{t('common.close')}</Button>
        )}

        {view === 'invite_manager' && selectedCompanyInvite && (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleAction(selectedCompanyInvite, 'accept')}
              disabled={isCompanyInviteActionLoading}
              loading={isCompanyInviteActionLoading}
            >
              {t('common.accept')}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={() => handleAction(selectedCompanyInvite, 'decline')}
              disabled={isCompanyInviteActionLoading}
              loading={isCompanyInviteActionLoading}
            >
              {t('common.decline')}
            </LoadingButton>
            <Button onClick={onBack}>{t('common.back')}</Button>
          </>
        )}

      </DialogActions>
    </Dialog>
  );
}