import { PaginatedResponse } from 'src/services/api.types';
import { Organization } from 'src/services/auth/auth.types';
import { ApplicationStage, ApplicationStatus } from 'src/services/applications/applications.types';

import { TenantType } from 'src/types/enums';

import { DocumentFile, DocumentType } from '../files/file.types';
import type { IJob, Skill, VisibilityRule } from '../jobs/jobs.types';

export interface GetCandidateJobsProps {
  candidateId: string;
  page: number;
  per_page: number;
  search_query?: string;
}

export interface CandidateProfile {
  id?: string;
  type?: string;
  sections?: CandidateProfileSection[];
  first_name?: string;
  email?: string;
  phone?: string;
  position_title?: string;
  last_name?: string;
  // send new "unclaimed" file to update profile picture or explicit "null" to remove existing image. undefined will ignore this field
  profile_image_id?: string | undefined;
  profile_image?: {
    id: string;
    public_path: string;
  };
  summary?: {
    content: string;
  };
  job_types?: CandidateProfileData[];
  work_styles?: CandidateProfileData[];
  education_level?: CandidateProfileData;
  work_rights?: CandidateProfileData[];
  salary_expectations?: ISalaryExpectation[];
  preferred_locations?: IPreferredLocation[];
  work_experiences?: IWorkExperience[];
  notice_period?: CandidateProfileData;
  skills?: Skill[];
  links?: IProfileLink[];
  education_history?: IEducationHistory[];
  availability_status?: CandidateAvailabilityStatus;
  available_from?: Date;

  updated_at: Date;
}

export interface ICandidateApplication {
  id: string;
  job: IJob;
  status: ApplicationStatus;
  pre_finalization_stage: ApplicationStage;
  candidate_profile: CandidateProfile;
  stage: ApplicationStage;
  created_at: Date;
  updated_at: Date;
}

export type CandidateApplicationsResponse = PaginatedResponse<ICandidateApplication>;

export interface CandidateProfileSection {
  type?: CandidateProfileSectionType;
  section_type?: CandidateProfileSectionType;
  visibility_rule: VisibilityRule;
  access_context?: string;
}

export interface CandidateProfileData {
  type?: string;
  style?: string;
  work_right?: string;
}

export interface IPreferredLocation {
  place_id: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface ISalaryExpectation {
  min_amount: number;
  max_amount: number;
  period: string;
}

export interface IWorkExperience {
  id?: string;
  company_name: string;
  position_title: string;
  role_summary: string;
  start: DateObj;
  end: DateObj | undefined;
  item_order: number;
  duration_text?: string;
  duration_months?: number;
  verification_status?: ExperienceVerificationStatus;
  verification_type?: ExperienceVerificationType;
  verified_at?: string;
  verified_by_domain?: null;
}

export interface IProfileLink {
  type: ProfileLinkType;
  url: string;
}

export enum ProfileLinkType {
  X = 'x',
  GITHUB = 'github',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  WEBSITE = 'website'
}

export interface IEducationHistory {
  id?: string;
  institution_name: string;
  certification_name: string;
  start_year: number;
  end_year: number;
  level: EducationLevel;
}

export enum EducationLevel {
  HIGH_SCHOOL = 'high_school',
  CERTIFICATE = 'certificate',
  DIPLOMA = 'diploma',
  UNDERGRADUATE_DEGREE = 'undergraduate_degree',
  POSTGRADUATE_DEGREE = 'postgraduate_degree',
}

export interface OrgCandidate {
  id: string;
  candidate_profile: CandidateProfile;
}

export enum CandidateAvailabilityStatus {
  UNAVAILABLE = 'unavailable',
  AVAILABLE_NOW = 'available_now',
  AVAILABLE_SOON = 'available_soon',
  INTERESTED = 'interested',
}

export interface DateObj {
  month: number;
  year: number;
}

export enum CandidateProfileSectionType {
  EXECUTIVE_SUMMARY = 'executive_summary',
  JOB_TYPES = 'job_types',
  SALARY_EXPECTATIONS = 'salary_expectations',
  WORK_LOCATIONS = 'work_locations',
  WORK_STYLES = 'working_styles',
  WORK_RIGHTS = 'work_rights',
  NOTICE_PERIOD = 'notice_period',
  EDUCATION_LEVEL = 'education_level',
  EDUCATION_HISTORY = 'education_history',
  WORK_EXPERIENCES = 'work_experiences',
  SKILLS = 'skills',
  LINKS = 'links',
}

export enum ExperienceVerificationStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  VERIFIED = 'verified',
}

export enum ExperienceVerificationType {
  SYSTEM = 'system',
  INVITEE = 'invitee',
}

export enum ExpVerificationAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum CandidateInviteStatus {
  UNSENT = 'unsent',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export enum CompanyInviteStatus {
  UNSENT = 'unsent',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export type RequestVerificationProps = {
  work_experience_id: string;
  data: {
    email: string;
    first_name: string;
    last_name: string;
  };
};

export type ExperienceVerificationRequest = {
  id: string;
  company_name: string;
  position_title: string;
  role_summary: string;
  start_month: string;
  start_year: string;
  end_month: string;
  end_year: string;
  created_at: string;
  candidate_profile: Partial<CandidateProfile>;
};

export type VerificationActionProps = {
  work_experience_id: string;
  data: {
    action: ExpVerificationAction;
    notes: string;
  };
};

export type UpdateAvailabilityProps = {
  status: CandidateAvailabilityStatus;
  available_from?: Date | null;
  withdraw_from_all?: boolean;
};

export type AvailabilityProfileResponse = {
  status: CandidateAvailabilityStatus;
  available_from: string;
  subscriber_accounts: string[];
  subscriber_organizations: string[];
};

export interface OutgoingCandidateInvite {
  id: string;
  status: CandidateInviteStatus;
  created_at: Date;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IncomingCandidateInvite extends OutgoingCandidateInvite {
  initiator: {
    company_name: string;
    company_information: string;
    company_type: string;
  };
}

export interface OutgoingCompanyInvite {
  id: string;
  status: CompanyInviteStatus;
  created_at: Date;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  target: Organization;
  previously_represented: boolean;
  position_title: string;
  represented_date: string;
}


export interface IncomingCompanyInvite extends OutgoingCompanyInvite {
  candidate_profile: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    position_title?: string;
  };
}

export interface CreateCompanyInviteDto {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  target_type: TenantType;
  previously_represented: boolean;
  position_title?: string;
  represented_date?: string;
}

export interface CreateCandidateInviteDto {
  email: string;
  first_name: string;
  last_name: string;
}

export interface InviteCandidatesResponse {
  email: string;
  result: 'success' | 'error';
  error: string | null;
}

export interface InviteCandidatesDTO {
  invites: CreateCandidateInviteDto[];
}

export interface GetCandidateInvitesProps {
  page: number;
  per_page: number;
  search_query?: string;
}

export interface GetCompanyInvitesProps {
  page: number;
  per_page: number;
  search_query?: string;
}

export interface ActionCandidateInviteProps {
  inviteId: string;
  action: 'accept' | 'decline';
}
export interface ActionCompanyInviteProps {
  inviteId: string;
  action: 'accept' | 'decline';
}

export interface CancelInviteProps {
  inviteId: string;
}

export type UploadProfileDocumentData = {
  type: DocumentType;
  file_id: string;
  visibility_rule?: VisibilityRule;
  expiry_date?: string;
  name: string;
};

export type CandidateProfileDocument = {
  id: string;
  document: DocumentFile;
  candidate_profile: CandidateProfile;
  visibility_rule?: VisibilityRule;
  expiry_date?: string;
};
