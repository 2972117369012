import { useSnackbar } from 'notistack';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Stack,
  Dialog,
  Button,
  Slider,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  DialogTitle,
  FormControl,
  ToggleButton,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fData } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { generateUploadError } from 'src/services/files/file.utils';
import { useUploadFileMutation } from 'src/services/files/file.service';

import Iconify from 'src/components/iconify';

import { UploadAvatar } from '../upload';
import getCroppedImageFile from './cropper-utils';

const RotationMarks = [
  { value: 0, label: '0°' },
  { value: 90, label: '90°' },
  { value: 180, label: '180°' },
  { value: 270, label: '270°' },
];

const ZoomMarks = [
  { value: 1, label: '1x' },
  { value: 2, label: '2x' },
  { value: 3, label: '3x' },
];

type CropShape = 'round' | 'rect' | 'square';

type AspectRatio = {
  value: number;
  label: string;
};

const ASPECT_RATIOS: AspectRatio[] = [
  { value: 1 / 1, label: '1:1' },
  { value: 16 / 9, label: '16:9' },
  { value: 4 / 3, label: '4:3' },
  { value: 3 / 2, label: '3:2' },
  { value: 2 / 3, label: '2:3' },
  { value: 3 / 4, label: '3:4' },
  { value: 9 / 16, label: '9:16' },
];

type Props = {
  onSubmit: (imageId: string) => void;
  existingUrl?: string;
  maxImageFileSize?: number;
  showGrid?: boolean;
  warningMessage?: string;
  initialShape?: CropShape;
  initialAspectRatio?: number;
};

export default function ImageCropper({
  onSubmit,
  existingUrl,
  maxImageFileSize = 3145728,
  showGrid = false,
  warningMessage,
  initialShape = 'round',
  initialAspectRatio = 1,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const showCropper = useBoolean();
  const [uploadFile] = useUploadFileMutation();

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [url, setUrl] = useState<string>('');
  const [cropShape, setCropShape] = useState<CropShape>(initialShape);
  const [aspectRatio, setAspectRatio] = useState<number>(initialAspectRatio);

  const [chosenCroppedArea, setChosenCroppedArea] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (existingUrl) setUrl(existingUrl);
  }, [existingUrl]);

  const onCropChange = (cropValue: Point) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setChosenCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (zoomValue: number) => {
    setZoom(zoomValue);
  };

  const handleShapeChange = (
    event: React.MouseEvent<HTMLElement>,
    newShape: CropShape | null
  ) => {
    if (newShape !== null) {
      setCropShape(newShape);
      // Reset to 1:1 aspect ratio for square shape
      if (newShape === 'square') {
        setAspectRatio(1);
      }
    }
  };

  const handleAspectRatioChange = (event: any) => {
    setAspectRatio(event.target.value);
  };

  const onSaveImage = async () => {
    const file = await getCroppedImageFile(url, chosenCroppedArea, rotation);
    if (!file) return;
    uploadEditedImage(file);
    showCropper.onFalse();
  };

  const uploadEditedImage = async (file: File) => {
    try {
      const uploadedFile = await uploadFile({
        file,
        type: 'image' as any,
      }).unwrap();
      onSubmit(uploadedFile.id);
    } catch (e) {
      enqueueSnackbar(generateUploadError(e), { variant: 'error' });
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      try {
        const uploadedFile = await uploadFile({
          file,
          type: 'image' as any,
        }).unwrap();
        setUrl(uploadedFile.public_path);
        if (file) {
          showCropper.onTrue();
        }
      } catch (e) {
        enqueueSnackbar(generateUploadError(e), { variant: 'error' });
      }
    },
    [enqueueSnackbar, showCropper, uploadFile]
  );

  const zoomValueText = (value: number) => `${value}x`;

  return (
    <Stack direction="column" alignItems="center">
      <UploadAvatar
        file={url}
        maxSize={maxImageFileSize}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 3,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            {warningMessage}
            <br /> {t('image_cropper.allowed_formats')}
            <br /> {t('image_cropper.max_size', { size: fData(maxImageFileSize) })}
          </Typography>
        }
      />

      {showCropper.value && (
        <Dialog fullWidth maxWidth="sm" open={showCropper.value} onClose={showCropper.onFalse}>
          <DialogTitle>{t('image_cropper.title')}</DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Stack direction="row" spacing={2} sx={{ px: 2, pt: 2 }}>
              <ToggleButtonGroup
                value={cropShape}
                exclusive
                onChange={handleShapeChange}
                aria-label="crop shape"
                size="small"
              >
                <ToggleButton value="round" aria-label="round">
                  <Iconify icon="mdi:circle-outline" />
                </ToggleButton>
                <ToggleButton value="square" aria-label="square">
                  <Iconify icon="mdi:square-outline" />
                </ToggleButton>
                <ToggleButton value="rect" aria-label="rectangle">
                  <Iconify icon="mdi:rectangle-outline" />
                </ToggleButton>
              </ToggleButtonGroup>

              {cropShape === 'rect' && (
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel>{t('image_cropper.aspect_ratio')}</InputLabel>
                  <Select
                    value={aspectRatio}
                    label={t('image_cropper.aspect_ratio')}
                    onChange={handleAspectRatioChange}
                  >
                    {ASPECT_RATIOS.map((ratio) => (
                      <MenuItem key={ratio.label} value={ratio.value}>
                        {ratio.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>

            <Box sx={{ minHeight: 400, py: 6, px: 2, overflow: 'hidden', position: 'relative' }}>
              <Cropper
                image={url}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                maxZoom={3}
                aspect={aspectRatio}
                rotation={rotation}
                onRotationChange={(rotationValue) => setRotation(rotationValue)}
                cropShape={cropShape as "rect" | "round"}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                showGrid={showGrid}
              />
            </Box>

            <Box sx={{ pt: 2, px: 2 }}>
              <Stack direction="row" columnGap={2}>
                <Box sx={{ flexGrow: 1, p: 1 }}>
                  <Typography variant="body2">{t('image_cropper.zoom')}</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    marks={ZoomMarks}
                    onChange={(event: any, value: number | number[]) => setZoom(value as number)}
                    aria-label="Zoom Slider"
                    valueLabelDisplay="auto"
                    getAriaValueText={zoomValueText}
                  />
                </Box>

                <Box sx={{ flexGrow: 1, p: 1 }}>
                  <Typography variant="body2">{t('image_cropper.rotation')}</Typography>
                  <Slider
                    value={rotation}
                    onChange={(event: any, value: number | number[]) =>
                      setRotation(value as number)
                    }
                    min={0}
                    max={360}
                    step={1}
                    marks={RotationMarks}
                    aria-label="Rotation Slider"
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Stack>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={showCropper.onFalse}>
              {t('common.cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onSaveImage}>
              {t('common.save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}