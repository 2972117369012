import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import { Card, Table, useTheme, TableBody, useMediaQuery, TableContainer } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { authApi } from 'src/services/auth/auth.service';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { OutgoingCandidateInvite } from 'src/services/candidates/candidates.types';
import { toggleCandidateInviteModal } from 'src/store/slices/candidate/candidateSlice';
import { useCancelOrgCandidateInviteMutation, useGetOutgoingOrgCandidateInvitesQuery } from 'src/services/candidates/candidates.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import InvitesTableRow from './invites-table-row';
import InvitesTableToolbar from './invites-table-toolbar';

const TABLE_HEAD = [
  { id: 'email', label: 'Email' },
  { id: 'Name', label: 'Name' },
  { id: 'invited_on', label: 'Invited On' },
  { id: 'controls', label: '', width: 120 },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 10,
};

export default function InvitesTable() {
  const table = useTable();

  const { t } = useTranslate();

  const theme = useTheme();

  const authContext = useAuthContext();

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { data: authData } = authResult;

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [filterQuery, setFilterQuery] = useState<string>('');

  const { currentData: outgoingInvites, isLoading: isInvitesLoading } = useGetOutgoingOrgCandidateInvitesQuery(
    {
      search_query: filterQuery,
      page: pageOptions.page,
      per_page: pageOptions.per_page,
    },
    { skip: !authContext.organization?.id });

  const [cancelInvite] = useCancelOrgCandidateInviteMutation();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const onRemoveInvite = useCallback(async (inviteId: string) => {
    if (!authData) return;

    try {

      await cancelInvite({ inviteId }).unwrap();

      enqueueSnackbar(t(''), { variant: 'success' });

    } catch (e) {
      enqueueSnackbar(t(''), { variant: 'error' });
    }
  }, [authData, t, cancelInvite]);

  const handleFilters = useMemo(
    () => debounce((filter: string, value: string) => {
      if (filter === 'search_query') {
        setFilterQuery(value as string);
      }
    }, 500),
    [setFilterQuery]
  );

  const clearFilters = useCallback(() => {
    setFilterQuery('');
  }, []);

  const notFound = !outgoingInvites?.results.length;

  const onChangePage = useCallback((_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: pageNumber + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageOptions((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleOpenInviteCandidatesModal = useCallback(() => {
    dispatch(toggleCandidateInviteModal({
      status: true
    }));
  }, [dispatch]);

  return (
    <Card sx={{ borderRadius: 0 }}>

      {
        isInvitesLoading ? (
          <TableSkeleton />
        )
          :
          <>
            <InvitesTableToolbar
              filterQuery={filterQuery}
              onFilters={handleFilters}
              clearFilters={clearFilters}
              onInviteCandidates={handleOpenInviteCandidatesModal}
            />
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  {
                    !isMobile && (
                      <TableHeadCustom
                        order={table.order}
                        headLabel={TABLE_HEAD}
                        rowCount={outgoingInvites?.results.length}
                        numSelected={table.selected.length}
                      />
                    )
                  }

                  <TableBody>
                    {outgoingInvites?.results?.map((row: OutgoingCandidateInvite) => (
                      <InvitesTableRow key={row.id} row={row} onDeleteRow={onRemoveInvite} />
                    ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, outgoingInvites?.results.length ?? 0)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={outgoingInvites?.count ?? 0}
              page={pageOptions.page}
              rowsPerPage={pageOptions.per_page}
              onPageChange={onChangePage as any}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
      }
    </Card>
  );
}
