
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { IProfileLink, ProfileLinkType } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';

import { LinkTypeMeta } from 'src/sections/profile/profile-sections/profile-links';


export default function LinksEditor() {

  const { t } = useTranslate();

  const { control, watch, setValue, formState: { errors } } = useFormContext();

  const links = watch('links');

  const onLinkChange = useCallback((index: number, type: ProfileLinkType, value: string) => {
    let updatedLinks = [...links];

    const typeExists = updatedLinks.find((link) => link.type === type);

    if (!typeExists) {
      updatedLinks[index] = { type, url: value };
    }
    else {
      updatedLinks[index] = { ...updatedLinks[index], url: value };
    }

    updatedLinks = updatedLinks.filter((link) => link.url && link.type);

    setValue('links', updatedLinks, { shouldValidate: true, shouldDirty: true });

  }, [links, setValue]);

  return (
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={2} gap={2}>

        {LinkTypeMeta.map((typeMeta, index) => (
          <Grid xs={12} key={index}>
            <TextField
              fullWidth
              label={typeMeta.label}
              placeholder={`Enter your ${typeMeta.type} URL`}
              type="url"
              value={links.find((link: IProfileLink) => link.type === typeMeta.type)?.url || ''}
              onChange={(event) => onLinkChange(index, typeMeta.type, event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={typeMeta.icon} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                )
              }}
            />

            {
              (errors as any).links?.[index]?.url && (
                <Typography color="error" variant="caption">
                  {t((errors as any).links[index].url.message)}
                </Typography>
              )
            }

          </Grid>
        ))}
      </Grid>
    </Box>
  );
}