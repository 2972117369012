import { useSnackbar } from 'notistack';

import {
  Box,
  Grid
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';

import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';

export default function JobTypeEditor() {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFMultiSelect
            fullWidth
            name="job_types"
            checkbox
            label={t('enums.job_type.input_label')}
            options={
              typeDefinitionData
                ? typeDefinitionData.JobType.map((jobType: string) => ({
                  label: t(`enums.job_type.${jobType}`),
                  value: jobType,
                }))
                : []
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}