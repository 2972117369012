import { localStorageGetItem } from 'src/utils/local-storage';

import { setSession } from 'src/auth/utils';
import { safeAxiosInstance } from 'src/services/axios';

import { rootApi } from '../rootApi';
import { APIResponse, AuthResponse, HTTPRequestType } from '../api.types';
import {
  LoginDTO,
  AuthContext,
  CreateAccountDTO,
  RegisterInviteDTO,
  RegisterFromIntentDTO,
  CompletePasswordResetDTO,
  RequestEmailVerificationDTO
} from './auth.types';

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    requestEmailVerification: builder.mutation<APIResponse, RequestEmailVerificationDTO>({
      query: (data: RequestEmailVerificationDTO) => ({
        url: '/auth/request-verification',
        method: HTTPRequestType.POST,
        data,
      }),
    }),
    createAccount: builder.mutation<AuthResponse, CreateAccountDTO>({
      query: (data: CreateAccountDTO) => ({
        url: '/auth/register',
        method: HTTPRequestType.POST,
        data,
      }),
      transformResponse: (response: AuthResponse) => {
        setSession(response.access_token);

        return response;
      },
    }),
    registerFromIntent: builder.mutation<AuthResponse, RegisterFromIntentDTO>({
      query: (data: RegisterFromIntentDTO) => ({
        url: '/auth/register-from-intent',
        method: HTTPRequestType.POST,
        data,
      }),
      transformResponse: (response: AuthResponse) => {
        setSession(response.access_token);

        return response;
      },
    }),

    verifyEmail: builder.mutation<APIResponse, string>({
      query: (code: string) => ({
        url: '/auth/verify-account',
        method: HTTPRequestType.POST,
        data: {
          code,
        },
      }),
    }),
    getAuthContext: builder.query<AuthContext, void>({
      query: () => ({ url: '/auth/me' }),
      providesTags: ['AuthContext'],
    }),

    checkAuthStatus: builder.query<AuthContext, void>({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {

        const token = localStorageGetItem('access_token', undefined);

        try {
          const response = await safeAxiosInstance.get('/auth/me', {
            headers: {
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          });
          return { data: response.data };
        } catch (error) {
          return {
            error: {
              status: error.response?.status,
              data: error.response?.data
            }
          };
        }
      },
      providesTags: ['AuthContext'],
    }),

    login: builder.mutation<AuthResponse, LoginDTO>({
      query: (data: LoginDTO) => ({
        url: '/auth/login',
        method: HTTPRequestType.POST,
        data,
      }),
      transformResponse: (response: AuthResponse) => {
        setSession(response.access_token);

        return response;
      },
    }),
    loginExternal: builder.mutation<AuthResponse, LoginDTO>({
      query: (data: LoginDTO) => ({
        url: '/auth/login-external',
        method: HTTPRequestType.POST,
        data,
      }),
      transformResponse: (response: AuthResponse) => {
        setSession(response.access_token);

        return response;
      },
    }),
    requestResetPassword: builder.mutation<APIResponse, RequestEmailVerificationDTO>({
      query: (data: RequestEmailVerificationDTO) => ({
        url: '/auth/password-reset/request',
        method: HTTPRequestType.POST,
        data,
      }),
    }),
    completePasswordReset: builder.mutation<APIResponse, CompletePasswordResetDTO>({
      query: (data: CompletePasswordResetDTO) => ({
        url: '/auth/password-reset/complete',
        method: HTTPRequestType.POST,
        data,
      }),
    }),
    acceptInvite: builder.mutation<AuthResponse, RegisterInviteDTO>({
      query: (data) => ({
        url: '/auth/register-from-invite',
        method: HTTPRequestType.POST,
        data,
      }),
      transformResponse: (response: AuthResponse) => {
        setSession(response.access_token);

        return response;
      },
    }),
  }),
});

export const {
  useRequestEmailVerificationMutation,
  useCreateAccountMutation,
  useRegisterFromIntentMutation,
  useVerifyEmailMutation,
  useGetAuthContextQuery,
  useCheckAuthStatusQuery,
  useLoginMutation,
  useLoginExternalMutation,
  useRequestResetPasswordMutation,
  useCompletePasswordResetMutation,
  useAcceptInviteMutation,
} = authApi;
