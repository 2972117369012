import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { JobInvite } from 'src/services/jobs/jobs.types';

import { ShowJobLinkModalActionProps } from './jobs-actions.types';

interface ShowPendingCandidateInvitesPayload {
  job_id?: string;
}

export interface IJobsState {
  currentJobId: string | undefined;
  showJobLinkActionModal: boolean;
  jobLinkRequestId: string | undefined;
  jobLinkRequestNotificationId: string | undefined;
  showJobVerificationModal: boolean;
  showCandidateJobRequestsModal: boolean;
  showCandidateJobInviteActionModal: boolean;
  candidateJobInvite: JobInvite | undefined;
  showPendingJobInvitesModal: boolean;
  showImportJobModal: boolean;
  showRequestJobChangesModal: boolean;
}

const initialState: IJobsState = {
  currentJobId: undefined,
  showJobLinkActionModal: false,
  jobLinkRequestId: undefined,
  jobLinkRequestNotificationId: undefined,
  showJobVerificationModal: false,
  showCandidateJobRequestsModal: false,
  showCandidateJobInviteActionModal: false,
  candidateJobInvite: undefined,
  showPendingJobInvitesModal: false,
  showImportJobModal: false,
  showRequestJobChangesModal: false,
};

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    showJobLinkActionModal: (state, action: PayloadAction<ShowJobLinkModalActionProps>) => {
      const { notificationId, requestId } = action.payload;
      state.showJobLinkActionModal = true;
      state.jobLinkRequestId = requestId;
      state.jobLinkRequestNotificationId = notificationId;
    },
    hideJobLinkActionModal: (state) => {
      state.showJobLinkActionModal = false;
      state.jobLinkRequestId = undefined;
      state.jobLinkRequestNotificationId = undefined;
    },
    showJobVerificationModal: (state) => {
      state.showJobVerificationModal = true;
    },
    hideJobVerificationModal: (state) => {
      state.showJobVerificationModal = false;
    },
    showCandidateJobRequestsModal: (state) => {
      state.showCandidateJobRequestsModal = true;
    },
    hideCandidateJobRequestsModal: (state) => {
      state.showCandidateJobRequestsModal = false;
    },
    showCandidateJobInviteActionModal: (state: IJobsState, action: PayloadAction<JobInvite>) => {
      state.candidateJobInvite = action.payload;
      state.showCandidateJobInviteActionModal = true;
    },
    hideCandidateJobInviteActionModal: (state) => {
      state.showCandidateJobInviteActionModal = false;
      state.candidateJobInvite = undefined;
    },

    showPendingJobInvitesModal: (
      state,
      action: PayloadAction<ShowPendingCandidateInvitesPayload>
    ) => {
      state.showPendingJobInvitesModal = true;
      if (action?.payload?.job_id) {
        state.currentJobId = action.payload.job_id;
      }
    },
    hidePendingJobInvitesModal: (state) => {
      state.currentJobId = undefined;
      state.showPendingJobInvitesModal = false;
    },
    showImportJobModal: (state) => {
      state.showImportJobModal = true;
    },
    hideImportJobModal: (state) => {
      state.showImportJobModal = false;
    },
    onShowRequestJobChangesModal: (state, action: PayloadAction<string>) => {
      state.showRequestJobChangesModal = true;
      state.currentJobId = action.payload;
    },
    onHideRequestJobChangesModal: (state) => {
      state.showRequestJobChangesModal = false;
      state.currentJobId = undefined;
    },
  },
});

export const {
  showJobLinkActionModal,
  hideJobLinkActionModal,
  showJobVerificationModal,
  hideJobVerificationModal,
  showCandidateJobRequestsModal,
  hideCandidateJobRequestsModal,

  showCandidateJobInviteActionModal,
  hideCandidateJobInviteActionModal,

  showPendingJobInvitesModal,
  hidePendingJobInvitesModal,
  showImportJobModal,
  hideImportJobModal,

  onShowRequestJobChangesModal,
  onHideRequestJobChangesModal,
} = jobsSlice.actions;
export const jobsStateReducer = jobsSlice.reducer;
