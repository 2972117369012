import { Helmet } from 'react-helmet-async';

import CompleteConnectIntentView from 'src/sections/auth/connect-intent/complete-connect-intent-view';

export default function CompleteConnectIntentPage() {
  return (
    <>
      <Helmet>
        <title>JT: Complete Registration</title>
      </Helmet>

      <CompleteConnectIntentView />
    </>
  );
}
