import { isString } from "lodash";

import i18n from "src/locales/i18n";

import { BusinessErrorCodes } from "src/types/business-errors";

export const generateCompleteApplyIntentErrorMessage = (e: any): string => i18n.t('auth.api.register.default_error');


export const generateCompleteConnectIntentErrorMessage = (e: any): string => i18n.t('auth.api.register.default_error');

export const generateInitiateConnectIntentErrorMessage = (e: any): string => {
    if (isString(e?.data?.error_code)) {
        if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyConnected) {
            return i18n.t('jobs.job_board.connect_manager.connect_modal.api.already_connected');
        }
    }

    return i18n.t('jobs.job_board.connect_manager.connect_modal.api.default_error');
}