import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  Grid
} from '@mui/material';

import { useTranslate } from 'src/locales';
import {
  ISalaryExpectation
} from 'src/services/candidates/candidates.types';

import RHFSalaryPicker from 'src/components/hook-form/rhf-salary-range-picker';

type SalaryExpectationsEditorProps = {
  current?: ISalaryExpectation[];
};

export default function SalaryExpectationsEditor({
  current = []
}: SalaryExpectationsEditorProps) {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  const { setValue, getValues } = useFormContext();

  const onAddSalaryExpectation = (salary: ISalaryExpectation) => {

    const { salary_expectations } = getValues();

    setValue('salary_expectations', [...(salary_expectations || []), salary], {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true
    });
  };

  const onRemoveSalaryExpectation = (salaryIndex: number) => {
    const { salary_expectations } = getValues();
    if (!salary_expectations?.length) return;

    salary_expectations.splice(salaryIndex, 1);
    setValue('salary_expectations', [...salary_expectations], {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>

          <RHFSalaryPicker
            name="salary_expectations"
            onAddSalary={onAddSalaryExpectation}
            onRemoveSalary={onRemoveSalaryExpectation}
          />

        </Box>
      </Grid>
    </Grid>
  );
}