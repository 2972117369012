import { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack } from '@mui/system';
import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  CardHeader,
  IconButton,
  Typography,
  CardContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { IWorkExperience } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import TruncatedHTMLRenderer from 'src/components/truncate-text/truncated-html-card';

import { WorkExperienceEditModal } from 'src/sections/profile/profile-sections/profile-work-history';

export default function WorkExperienceEditor() {

  const { watch, setValue } = useFormContext();

  const { t } = useTranslate();

  const showAddExperienceModal = useBoolean();

  const workExperiences: IWorkExperience[] = watch('work_experiences');

  const [selectedExperience, setSelectedExperience] = useState<IWorkExperience | undefined>(
    undefined
  );

  const onCloseEditor = () => {
    setSelectedExperience(undefined);
    showAddExperienceModal.onFalse();
  };

  const onSaveExperiences = useCallback((experiences: IWorkExperience[]) => {
    setValue('work_experiences', experiences);
    showAddExperienceModal.onFalse();
  }, [setValue, showAddExperienceModal]);

  const onUpdateExperience = useCallback((experience: IWorkExperience) => {

    const existingExperienceIndex = workExperiences.findIndex((exp: IWorkExperience) => exp.id === experience.id);
    let updatedExperiences = [];

    if (existingExperienceIndex !== -1) {
      updatedExperiences = [...workExperiences];
      updatedExperiences[existingExperienceIndex] = experience;
    } else {
      updatedExperiences = [experience, ...workExperiences];
    }

    onSaveExperiences(updatedExperiences);
  }, [workExperiences, onSaveExperiences]);

  const onRemoveExperience = useCallback((experienceIndex?: number) => {
    const updatedExperiences = workExperiences.filter((_: IWorkExperience, index: number) => index !== experienceIndex);

    onSaveExperiences(updatedExperiences);
  }, [workExperiences, onSaveExperiences]);

  const onOpenEditor = (experienceIndex?: number) => {
    const selectedExp = experienceIndex !== undefined
      ? workExperiences[experienceIndex]
      : undefined;

    if (selectedExp) {
      setSelectedExperience(selectedExp);
    }

    showAddExperienceModal.onTrue();
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>

          {
            workExperiences?.length ? (
              workExperiences.map((experience: IWorkExperience, index: number) => (
                <Card key={experience.id} sx={{ mb: 2 }}>
                  <CardHeader
                    sx={{
                      flexWrap: 'wrap',
                    }}
                    title={experience.position_title}
                    subheader={experience.company_name}
                    avatar={
                      <Avatar src="" alt={experience?.company_name}>
                        {experience?.company_name?.charAt(0)}
                      </Avatar>
                    }
                    action={
                      <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                        <IconButton
                          className="text-gray-500"
                          onClick={() => onOpenEditor(index)}
                        >
                          <Iconify icon="solar:pen-bold" />
                        </IconButton>
                        <IconButton
                          className="text-gray-500 delete-experience-button"
                          onClick={() => onRemoveExperience(index)}
                        >
                          <Iconify icon="mdi:delete" />
                        </IconButton>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Grid container wrap="wrap">
                      <Grid xs={12} md={6}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {experience.duration_text}
                        </Typography>
                      </Grid>
                      <Grid xs={12} pt={1}>
                        <TruncatedHTMLRenderer html={experience.role_summary} mode="lines" limit={3} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography textAlign="center" variant='subtitle2' className="text-gray-400">
                {t('profile.sections.work_history.no_experiences')}
              </Typography>
            )}

          <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
            <IconButton
              color='primary'
              size='large'
              className="text-gray-500"
              onClick={() => onOpenEditor()}
            >
              <Tooltip title={t('profile.sections.work_history.add_experience')}>
                <Iconify icon="carbon:add-filled" width={30} height={30} />
              </Tooltip>
            </IconButton>
          </Stack>
        </Box>
      </Grid>

      <WorkExperienceEditModal
        open={showAddExperienceModal.value}
        onClose={onCloseEditor}
        experience={selectedExperience}
        addExperience={onUpdateExperience}
      />
    </Grid>
  );
}