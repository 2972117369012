import * as Yup from 'yup';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Skeleton, useTheme, TextField, IconButton, InputAdornment } from '@mui/material';

import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  IProfileLink,
  ProfileLinkType,
  CandidateProfile,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';
import RHFVisibilityToggle from 'src/components/hook-form/rhf-visibility-toggle';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

const urlRegex = /^https?:\/\/.+/;

export const LinkTypeMeta = [
  {
    type: ProfileLinkType.GITHUB,
    label: t('profile.sections.links.types.github'),
    icon: 'carbon:logo-github',
  },
  {
    type: ProfileLinkType.LINKEDIN,
    label: t('profile.sections.links.types.linkedin'),
    icon: 'brandico:linkedin-rect',
  },
  {
    type: ProfileLinkType.X,
    label: t('profile.sections.links.types.x'),
    icon: 'prime:twitter',
  },
  {
    type: ProfileLinkType.FACEBOOK,
    label: t('profile.sections.links.types.facebook'),
    icon: 'brandico:facebook-rect',
  },
  {
    type: ProfileLinkType.WEBSITE,
    label: t('profile.sections.links.types.website'),
    icon: 'solar:global-outline',
  }
]

export default function ProfileLinksEditor({ profile, isLoading, mode = 'edit' }: Props) {
  const [updateProfile] = useSetCandidateProfileMutation();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const defaultValues = {
    links: LinkTypeMeta.map(meta => ({
      type: meta.type,
      url: '',
    })),
    links_visibility: true,
  };

  const validationSchema = Yup.object().shape({
    links: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(),
        url: Yup.string()
          .url(t('validation.url'))
          .nullable(),
      })
    ),
    links_visibility: Yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty, errors },
    watch,
    reset,
    setValue,
  } = formMethods;

  const links = watch('links') as IProfileLink[];

  const setProfileData = () => {
    if (!profile || isLoading) return;

    const initialLinks = LinkTypeMeta.map(typeMeta => {
      const existingLink = profile.links?.find(link => link.type === typeMeta.type);

      return {
        type: typeMeta.type as ProfileLinkType,
        url: existingLink?.url || '',
      };
    });

    reset(
      {
        links: initialLinks,
        links_visibility:
          profile.sections?.find(
            (section) => section.section_type === CandidateProfileSectionType.LINKS
          )?.visibility_rule === VisibilityRule.PUBLIC,
      },
      { keepDirty: false }
    );
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onLinkChange = useCallback((index: number, value: string) => {
    const updatedLinks = [...links];
    updatedLinks[index] = { ...updatedLinks[index], url: value };

    setValue('links', updatedLinks, { shouldValidate: true, shouldDirty: true });

  }, [links, setValue]);

  const onCopyLink = useCallback((index: number) => {

    if (!navigator.clipboard) return;

    const link = links[index];
    navigator.clipboard.writeText(link.url);

    enqueueSnackbar(t('common.copied_to_clipboard'), { variant: 'success' });
  }, [links, enqueueSnackbar]);

  const onOpenLink = useCallback((index: number) => {

    const link = links[index];

    if (!link.url) return;

    window.open(link.url, '_blank');
  }, [links]);


  const onSubmit = handleSubmit(async (data) => {
    try {
      const validatedLinks = data.links ? data.links.filter((link) => link.url).map((link) => ({
        type: link.type as ProfileLinkType,
        url: link.url as string,
      })) : [];

      await updateProfile({
        id: profile?.id as string,
        data: {
          links: validatedLinks,
          sections: [
            {
              type: CandidateProfileSectionType.LINKS,
              visibility_rule: data.links_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
          ],
        },
      }).unwrap();


      
    } catch (e) {
      console.log(e);
      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  const links_visibility = watch('links_visibility');

  const renderForm = () => (
    <>
      {
        isLoading ? (
          <Grid
            xs={12}
          >
            <Skeleton variant="rectangular" height={100} />
          </Grid>
        ) : (
          <FormProvider methods={formMethods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              
              {/* <ProfileLinksList /> */}

              {LinkTypeMeta.map((typeMeta, index) => (
                <Grid xs={12} key={index}>
                  <Controller
                    name={`links.${index}.url`}
                    control={formMethods.control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={typeMeta.label}
                        placeholder={`Enter your ${typeMeta.type} URL`}
                        type="url"
                        onChange={(event) => onLinkChange(index, event.target.value)}
                        error={!!error}
                        disabled={mode === 'view'}
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={typeMeta.icon} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                          ),
                          endAdornment: mode === 'view' && (
                            <Stack direction="row" gap={1}>

                              <IconButton
                                size="small"
                                onClick={() => onOpenLink(index)}
                                disabled={!field.value}
                                title={t('common.open')}
                                sx={{
                                  backgroundColor: theme.palette.background.paper,
                                  '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                  },
                                  boxShadow: theme.shadows[2],
                                }}
                              >
                                <Iconify icon="gridicons:external" />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={() => onCopyLink(index)}
                                disabled={!field.value}
                                title={t('common.copy_link')}
                                sx={{
                                  backgroundColor: theme.palette.background.paper,
                                  '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                  },
                                  boxShadow: theme.shadows[2],
                                }}
                              >
                                <Iconify icon="material-symbols:content-copy" />
                              </IconButton>
                            </Stack>
                          )
                        }}
                      />

                    )}
                  />
                </Grid>
              ))}
            </Grid>
            {
              mode === 'edit' && (
                <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
                  <RHFVisibilityToggle
                    name="links_visibility"
                    labelPosition="left"
                    checked={links_visibility}
                    onLabel={t('common.visible')}
                    offLabel={t('common.hidden')}
                    tooltip={t('profile.form.toggle_tooltip')}
                  />

                  <LoadingButton
                    variant="contained"
                    color="success"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid || !isDirty}
                  >
                    {t('common.save')}
                  </LoadingButton>
                </CardActions>
              )
            }
          </FormProvider>
        )
      }
    </>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.links.title')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
