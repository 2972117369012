import { useSnackbar } from 'notistack';

import {
  Box,
  Grid,
  MenuItem
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';

import { RHFSelect } from 'src/components/hook-form/rhf-select';

type EducationLevelEditorProps = {
  current?: string;
};

export default function EducationLevelEditor({
  current
}: EducationLevelEditorProps) {

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFSelect
            fullWidth
            name="education_level"
            label={t('enums.education_level.input_label')}
          >
            <MenuItem key="default" value="">
              {t('common.none')}
            </MenuItem>
            {typeDefinitionData
              ? typeDefinitionData.EducationLevel.map((educationLevel: string) => (
                <MenuItem key={educationLevel} value={educationLevel}>
                  {t(`enums.education_level.${educationLevel}`)}
                </MenuItem>
              ))
              : null}
          </RHFSelect>
        </Box>
      </Grid>
    </Grid>
  );
}