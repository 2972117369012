import _ from 'lodash';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Tab, Card, List, Tabs, Alert, Stack, Avatar, Button, Dialog, Divider, Tooltip, ListItem, IconButton, Pagination, Typography, CardContent, DialogTitle, ListItemText, DialogActions, DialogContent, ListItemAvatar } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { OutgoingCompanyInvite, IncomingCandidateInvite } from 'src/services/candidates/candidates.types';
import { toggleCompanyInviteModal, toggleCandidateInviteListModal } from 'src/store/slices/candidate/candidateSlice';
import {
  useCancelCompanyInviteMutation,
  useGetOutgoingCompanyInvitesQuery,
  useActionOrgCandidateInviteMutation,
  useGetIncomingOrgCandidateInvitesQuery,
} from 'src/services/candidates/candidates.service';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import DateDisplay from 'src/components/date/date-display';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { TenantType } from 'src/types/enums';

type PaginationControlProps = {
  page: number;
  perPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
};

const PaginationControl = ({ page, perPage, totalCount, onPageChange }: PaginationControlProps) => (
  <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
    <Pagination
      page={page || 1}
      count={Math.ceil((totalCount || 1) / perPage)}
      onChange={(_e, value) => onPageChange(value)}
    />
  </Stack>
);


type CandidateInviteListItemProps = {
  invite: IncomingCandidateInvite;
  onManage: (invite: IncomingCandidateInvite) => void;
};

type CompanyInviteListItemProps = {
  invite: OutgoingCompanyInvite;
  onCancel: (invite: OutgoingCompanyInvite) => void;
};

const CandidateInviteListItem = ({ invite, onManage }: CandidateInviteListItemProps) => (
  <>
    <ListItem sx={{ mb: 1 }} secondaryAction={
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        aria-label="manage"
        onClick={() => onManage(invite)}
      >
        {t('common.manage')}
      </Button>
    }>
      <ListItemAvatar>
        <Avatar>{invite.initiator.company_name?.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{
          paddingRight: 14,
        }}
        primary={_.capitalize(`${invite.initiator.company_name}`)}
        secondary={_.capitalize(invite.initiator.company_type)}
      />
    </ListItem>
    <Divider variant="middle" component="li" />
  </>
);

const CompanyInviteListItem = ({ invite, onCancel }: CompanyInviteListItemProps) => (
  <>
    <ListItem sx={{ mb: 1 }} secondaryAction={
      <IconButton
        edge="end"
        aria-label="cancel"
        onClick={() => onCancel(invite)}
        sx={{ color: 'error.main' }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    }>
      <ListItemAvatar>
        <Avatar>{invite.company_name?.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{
          paddingRight: 14,
        }}
        primary={_.capitalize(`${invite.company_name}`)}
        secondary={
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              {_.capitalize(invite.target.type)}
            </Typography>
            |
            <DateDisplay prefix={t('candidates.invites.sent_on')} variant="body2" date={invite.created_at} format='DD/MM/YYYY' />
          </Stack>
        }
      />
    </ListItem>
    <Divider variant="middle" component="li" />
  </>
);

type CandidateInviteDetailProps = {
  invite: IncomingCandidateInvite;
};

const CandidateInviteDetail = ({ invite }: CandidateInviteDetailProps) => (
  <Card sx={{ boxShadow: 'unset' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ mr: 2 }}>{invite.initiator.company_name.charAt(0)}</Avatar>
        <Stack direction="column">
          <Typography variant="h6">{invite.initiator.company_name}</Typography>
          <Typography variant="body1" color="text.secondary" textTransform="capitalize">
            {invite.initiator.company_type}
          </Typography>
        </Stack>
      </Box>
      <Typography variant="body2" color="text.secondary" textTransform="capitalize">
        {invite.initiator.company_information}
      </Typography>
      <Alert severity="info" sx={{ my: 2 }}>
        {t('candidates.invites.candidate_invites_modal.action_alert')}
      </Alert>
    </CardContent>
  </Card>
);

type CompanyInviteDetailProps = {
  invite: OutgoingCompanyInvite;
};

const CompanyInviteDetail = ({ invite }: CompanyInviteDetailProps) => (
  <Card sx={{ boxShadow: 'unset' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ mr: 2 }}>{invite.company_name.charAt(0)}</Avatar>
        <Stack direction="column">
          <Typography variant="h6">{invite.company_name}</Typography>
          <Typography variant="body1" color="text.secondary" textTransform="capitalize">
            {invite.target.type}
          </Typography>
        </Stack>
      </Box>
      <Alert severity="warning" sx={{ my: 2 }}>
        {t('candidates.invites.candidate_invites_modal.cancel_alert')}
      </Alert>
    </CardContent>
  </Card>
);

type Props = {
  open: boolean;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

enum TabValue {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export default function CandidateInvitesListModal({ open }: Props) {

  const dispatch = useAppDispatch();
  const tenant = useOrgTenant();
  const { enqueueSnackbar } = useSnackbar();

  const cancelInviteConfirm = useBoolean();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tab, setTab] = useState<TabValue>(TabValue.Incoming);
  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [view, setView] = useState<'invite_list' | 'invite_manager'>('invite_list');

  const [selectedCandidateInvite, setSelectedCandidateInvite] = useState<IncomingCandidateInvite | null>(null);
  const [selectedCompanyInvite, setSelectedCompanyInvite] = useState<OutgoingCompanyInvite | null>(null);

  const { currentData: incomingCandidateInvites } = useGetIncomingOrgCandidateInvitesQuery(pageOptions, {
    skip: tenant !== TenantType.Candidate,
  });

  const { currentData: outgoingCompanyInvites } = useGetOutgoingCompanyInvitesQuery(pageOptions, {
    skip: tenant !== TenantType.Candidate,
  });

  const [actionCandidateInvite, { isLoading: isCandidateInviteActionLoading }] = useActionOrgCandidateInviteMutation();
  const [cancelCompanyInvite, { isLoading: isCancellingCompanyInvite }] = useCancelCompanyInviteMutation();

  const onClose = useCallback(() => {

    dispatch(toggleCandidateInviteListModal({
      status: false
    }));

    setView('invite_list');
    setSelectedCandidateInvite(null);
  }, [dispatch]);

  const changePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const changeTab = useCallback((event: React.SyntheticEvent, newValue: TabValue) => {
    setPageOptions(DEFAULT_PAGE);
    setTab(newValue);
  }, []);

  const onManageRequest = useCallback((invite: IncomingCandidateInvite) => {
    setSelectedCandidateInvite(invite);
    setView('invite_manager');
  }, []);

  const onBack = useCallback(() => {
    setView('invite_list');
    setSelectedCandidateInvite(null);
    setSelectedCompanyInvite(null);
  }, []);

  const handleAction = async (invite: IncomingCandidateInvite, action: 'accept' | 'decline') => {
    setErrorMessage(null);
    try {
      await actionCandidateInvite({ inviteId: invite.id, action }).unwrap();
      enqueueSnackbar(t(`candidates.invites.candidate_invites_modal.api.success_${action}`), { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.api.default_error'), { variant: 'error' });
    }
  };

  const onCancelCompanyInvite = (invite: OutgoingCompanyInvite) => {
    setSelectedCompanyInvite(invite);
    cancelInviteConfirm.onTrue();
  }

  const handleCloseCancelInviteConfirm = () => {
    cancelInviteConfirm.onFalse();
    setSelectedCompanyInvite(null);
  }

  const handleCancelCompanyInvite = useCallback(async () => {

    if (!selectedCompanyInvite) return;

    try {
      cancelInviteConfirm.onFalse();
      await cancelCompanyInvite(selectedCompanyInvite?.id).unwrap();
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.company_invites.api.cancel.success'), { variant: 'success' });

      setSelectedCompanyInvite(null);

    } catch (error) {
      console.log(error)
      enqueueSnackbar(t('candidates.invites.candidate_invites_modal.company_invites.api.cancel.default_error'), { variant: 'error' });
    }
  }, [selectedCompanyInvite, cancelCompanyInvite, enqueueSnackbar, cancelInviteConfirm]);

  const onAddNewInvite = useCallback(() => {
    dispatch(toggleCompanyInviteModal({
      status: true,
      targetTenant: TenantType.Recruiter
    }));
  }, [dispatch]);

  const renderInviteList = () => (
    <Stack direction="column">
      {errorMessage ? (
        <Alert variant="outlined" severity="error" sx={{ mb: 1 }}>
          {errorMessage}
        </Alert>
      ) : (
        <Alert variant="outlined" severity="info" sx={{ mb: 1 }}>
          {t('candidates.invites.candidate_invites_modal.alert')}
        </Alert>
      )}

      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Tabs
          value={tab}
          onChange={changeTab}
          aria-label="Candidate invite navigation tabs"
          role="navigation"
        >
          <Tab label={t('common.incoming')} value={TabValue.Incoming}/>
          <Tab label={t('common.outgoing')} value={TabValue.Outgoing} />
        </Tabs>

        {
          tab === TabValue.Outgoing && (
            <IconButton
              onClick={onAddNewInvite}
              sx={{ color: 'primary.main', mt: 1 }}
              aria-label="add-invite"
            >
              <Tooltip title={t('candidates.invites.tooltips.new_invite')}>
                <Iconify icon="solar:add-circle-bold" />
              </Tooltip>
            </IconButton>
          )
        }

      </Stack>

      {tab === TabValue.Incoming && (
        <>
          {incomingCandidateInvites?.count ? (
            <List dense>
              {incomingCandidateInvites?.results?.map((invite, index) => (
                <CandidateInviteListItem
                  key={index}
                  invite={invite}
                  onManage={onManageRequest}
                />
              ))}
            </List>
          ) : (
            <EmptyContent filled title="No Data" sx={{ py: 10 }} />
          )}
          <PaginationControl
            page={pageOptions.page}
            perPage={pageOptions.per_page}
            totalCount={incomingCandidateInvites?.count || 0}
            onPageChange={changePage}
          />
        </>
      )}

      {tab === TabValue.Outgoing && (
        <>
          {outgoingCompanyInvites?.count ? (
            <List dense>
              {outgoingCompanyInvites?.results?.map((invite, index) => (
                <CompanyInviteListItem
                  key={index}
                  invite={invite}
                  onCancel={onCancelCompanyInvite}
                />
              ))}
            </List>
          ) : (
            <EmptyContent filled title="No Data" sx={{ py: 10 }} />
          )}
          <PaginationControl
            page={pageOptions.page}
            perPage={pageOptions.per_page}
            totalCount={outgoingCompanyInvites?.count || 0}
            onPageChange={changePage}
          />

          <ConfirmDialog
            open={cancelInviteConfirm.value}
            onClose={handleCloseCancelInviteConfirm}
            title={t('common.cancel')}
            content={t('candidates.invites.candidate_invites_modal.company_invites.cancel_confirm')}
            action={
              <Button variant="contained" color="error" onClick={handleCancelCompanyInvite}>
                {t('common.yes')}
              </Button>
            }
          />

        </>
      )}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t('candidates.invites.candidate_invites_modal.title', {
          target: t(tab === TabValue.Incoming ? 'common.incoming' : 'common.outgoing'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {view === 'invite_list' ? (
            renderInviteList()
          ) : (
            <>
              {selectedCandidateInvite && <CandidateInviteDetail invite={selectedCandidateInvite} />}
              {selectedCompanyInvite && <CompanyInviteDetail invite={selectedCompanyInvite} />}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {view === 'invite_list' && (
          <Button onClick={onClose}>{t('common.close')}</Button>
        )}

        {view === 'invite_manager' && selectedCandidateInvite && (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleAction(selectedCandidateInvite, 'accept')}
              disabled={isCandidateInviteActionLoading}
              loading={isCandidateInviteActionLoading}
            >
              {t('common.accept')}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={() => handleAction(selectedCandidateInvite, 'decline')}
              disabled={isCandidateInviteActionLoading}
              loading={isCandidateInviteActionLoading}
            >
              {t('common.decline')}
            </LoadingButton>
            <Button onClick={onBack}>{t('common.back')}</Button>
          </>
        )}

      </DialogActions>
    </Dialog>
  );
}