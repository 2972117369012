import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { TenantType } from 'src/types/enums';

export interface ICandidateState {
  showAvailabilityManagerModal: boolean;
  showCompanyInviteModal: boolean;
  showCandidateInviteModal: boolean;
  companyInviteTargetTenant?: TenantType;
  showCompanyInviteListModal: boolean;
  showCandidateInviteListModal: boolean;
}

const initialState: ICandidateState = {
  showAvailabilityManagerModal: false,
  showCompanyInviteModal: false,
  showCandidateInviteModal: false,
  companyInviteTargetTenant: undefined,

  showCompanyInviteListModal: false,
  showCandidateInviteListModal: false,
};

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setAvailabilityManagerModalVisibility: (state, action: PayloadAction<{ status: boolean }>) => {
      state.showAvailabilityManagerModal = action.payload.status;
    },

    toggleCompanyInviteModal: (state, action: PayloadAction<{ status: boolean; targetTenant?: TenantType }>) => {
      state.showCompanyInviteModal = action.payload.status;
      state.companyInviteTargetTenant = action.payload.status ? action.payload.targetTenant : undefined;
    },

    toggleCompanyInviteListModal: (state, action: PayloadAction<{ status: boolean }>) => {
      state.showCompanyInviteListModal = action.payload.status;
    },

    toggleCandidateInviteListModal: (state, action: PayloadAction<{ status: boolean }>) => {
      state.showCandidateInviteListModal = action.payload.status;
    },

    toggleCandidateInviteModal: (state, action: PayloadAction<{ status: boolean }>) => {
      state.showCandidateInviteModal = action.payload.status;
    },
  },
});

export const {
  setAvailabilityManagerModalVisibility,
  toggleCompanyInviteModal,
  toggleCompanyInviteListModal,
  toggleCandidateInviteModal,

  toggleCandidateInviteListModal,
} = candidateSlice.actions;

export const candidateeducer = candidateSlice.reducer;
