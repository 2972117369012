import * as Yup from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Grid, Stack, Button, MenuItem, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { DocumentType } from 'src/services/files/file.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { generateUploadError } from 'src/services/files/file.utils';
import { FeeType, FeeTypeDisplay } from 'src/services/jobs/jobs.types';
import { useGetTenantTermsQuery } from 'src/services/system/system.service';
import { useGetMySubscriptionQuery } from 'src/services/billing/billing.services';
import {
  useUploadFileMutation,
  useCreateDocumentMutation,
} from 'src/services/files/file.service';
import {
  TermsType,
  ConnectionTermsType,
  ConnectionTermsSettings,
} from 'src/services/connections/connections.types';

import Iconify from 'src/components/iconify';
import { Upload } from 'src/components/upload';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import RHFUnitInput from 'src/components/hook-form/rhf-unit-input';
import UploadItemCard from 'src/components/upload/upload-item-card';
import SelectableCard from 'src/components/selectable-card/selectable-card';

import ViewTermsModal from 'src/sections/terms/view-terms-modal';

import { TenantType } from 'src/types/enums';

export type Props = {
  onNextPage: (data: ConnectionTermsSettings, file?: File) => void;
  onBack: VoidFunction;
  submitting: boolean;
};

interface TemplateFieldData {
  date?: string | undefined;
  rate: string;
  payment_terms: string;
  special_conditions?: string;
}

export default function BusinessTermsPicker({ onNextPage, onBack, submitting }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File>();

  const termsPreviewControl = useBoolean();

  const [documentId, setDocumentId] = useState<string>();

  const { isError: hasNoSubscription } = useGetMySubscriptionQuery();

  const { currentData: termsData } = useGetTenantTermsQuery({
    type: TermsType.TERMS_OF_ENGAGEMENT,
  });

  const [uploadFile, { isLoading: uploadingFile }] = useUploadFileMutation();

  const [createDocument, { isLoading: creatingDocument }] = useCreateDocumentMutation();

  const { currentData: activeToeTerms, isLoading: retrievingActiveTerms, isError: errorRetrievingTerms } = useGetTenantTermsQuery({
    type: TermsType.TERMS_OF_ENGAGEMENT
  });

  const [selectedTermsType, setSelectedTermsType] = useState<ConnectionTermsType>(
    ConnectionTermsType.Terms_Of_Engagement
  );

  const onSelectTermsType = (setting: ConnectionTermsType) => {
    setSelectedTermsType(setting);
  };

  const TermsTemplateSchema = Yup.object().shape({
    date: Yup.date().required(t('validation.required')).nullable(),

    rate: Yup.number()
      .required(t('validation.required'))
      .when('unit', ([unit], schema) =>
        unit === FeeTypeDisplay.Percentage
          ? schema.max(100, t('admin-connections.create_modal.template.rate.max_percentage'))
          : schema
      ),

    payment_terms_days: Yup.number()
      .required(t('validation.required'))
      .min(0, t('validation.min_zero')),

    payment_terms: Yup.string().required(t('validation.required')),

    special_conditions: Yup.string().max(300, t('validation.max_length', { length: 300 })),

    unit: Yup.string(),
  });

  const defaultTemplateValues = {
    date: null,
    rate: 0,
    payment_terms_days: 0,
    payment_terms: '',
    special_conditions: '',
    unit: FeeTypeDisplay.Fixed,
  };

  const methods = useForm({
    resolver: yupResolver(TermsTemplateSchema),
    defaultValues: defaultTemplateValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = methods;

  const date = watch('date');

  const submitForm = handleSubmit(async (data) => {

    let rateString: string = `${data.rate}`;
    if (data.unit === FeeTypeDisplay.Percentage) {
      rateString = `${data.rate}%`;
    }
    else if (data.unit === FeeTypeDisplay.Fixed) {
      rateString = `$${data.rate}`;
    }

    const submitData = {
      date: moment(data.date).format('DD/MM/YYYY'),
      rate: rateString,
      payment_terms: `${data.payment_terms_days} ${data.payment_terms}`,
      special_conditions: data.special_conditions,
    };

    nextPageHandler(submitData);
  });

  const nextPageHandler = (data?: TemplateFieldData) => {
    let connectionTerms: ConnectionTermsSettings;

    if (!termsData) return;

    switch (selectedTermsType) {
      case ConnectionTermsType.Terms_Of_Engagement:
        connectionTerms = {
          terms_type: ConnectionTermsType.Terms_Of_Engagement,
          terms_id: termsData.id,
        };
        break;

      case ConnectionTermsType.Template:
        connectionTerms = {
          terms_type: ConnectionTermsType.Template,
          template_data: {
            date: data?.date ?? '',
            rate: data?.rate ?? '',
            payment_terms: data?.payment_terms ?? '',
            special_conditions: data?.special_conditions ?? '',
          },
        };
        break;

      case ConnectionTermsType.Custom:
        connectionTerms = {
          terms_type: ConnectionTermsType.Custom,
          documents: [{ id: documentId as string }],
        };
        break;

      default:
        return;
    }

    onNextPage(connectionTerms);
  };

  const disableContinue = useMemo(() => {
    if (selectedTermsType === ConnectionTermsType.Terms_Of_Engagement && !!termsData) {
      return false;
    }

    if (selectedTermsType === ConnectionTermsType.Template && isValid) {
      return false;
    }

    if (
      selectedTermsType === ConnectionTermsType.Custom &&
      !uploadingFile &&
      !creatingDocument &&
      documentId &&
      file
    ) {
      return false;
    }

    return true;
  }, [creatingDocument, documentId, file, isValid, selectedTermsType, termsData, uploadingFile]);

  const handleChangeUnit = useCallback(
    (value: FeeType) => {
      setValue('unit', value, { shouldValidate: true });
    },
    [setValue]
  );

  const handleDocumentUpload = useCallback(
    async (documents: File[]) => {
      setFile(documents[0]);

      try {
        const uploadedFile = await uploadFile({
          file: documents[0],
          type: 'document' as any,
        }).unwrap();

        const document = await createDocument({
          organization_id: authContext.organization.id,
          document: {
            file_id: uploadedFile.id,
            name: 'Custom Terms of business',
            type: DocumentType.TERMS_OF_BUSINESS,
          },
        }).unwrap();

        setDocumentId(document.id);
      } catch (e) {
        enqueueSnackbar(generateUploadError(e), { variant: 'error' });
      }
    },
    [authContext.organization.id, createDocument, enqueueSnackbar, uploadFile]
  );

  const unit = watch('unit');

  return (
    <FormProvider methods={methods} onSubmit={submitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ position: 'relative' }}>
            <SelectableCard
              title={t('admin-connections.create_modal.terms_of_engagement.standard.title')}
              subtitle={t('admin-connections.create_modal.terms_of_engagement.standard.subtitle')}
              selected={selectedTermsType === ConnectionTermsType.Terms_Of_Engagement}
              onClick={() => onSelectTermsType(ConnectionTermsType.Terms_Of_Engagement)}
            />
            <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
              {
                activeToeTerms && (
                  <IconButton
                    onClick={() => termsPreviewControl.onTrue()}
                    disabled={errorRetrievingTerms}
                  >
                    <Iconify icon="carbon:view-filled" />
                  </IconButton>
                )
              }
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <SelectableCard
            title={t('admin-connections.create_modal.terms_of_engagement.template.title')}
            subtitle={t('admin-connections.create_modal.terms_of_engagement.template.subtitle')}
            flexDirection={selectedTermsType === ConnectionTermsType.Template ? 'column' : 'row'}
            selected={selectedTermsType === ConnectionTermsType.Template}
            onClick={() => onSelectTermsType(ConnectionTermsType.Template)}
            disabled={hasNoSubscription}
          >
            {selectedTermsType === ConnectionTermsType.Template && (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <MobileDatePicker
                    name="date"
                    label={t('admin-connections.create_modal.template.date')}
                    value={date}
                    onChange={(newValue) => {
                      setValue('date', newValue);
                    }}
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'cancel', 'today', 'accept'],
                      },
                      textField: {
                        fullWidth: true,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <RHFUnitInput
                    name="rate"
                    label={t('admin-connections.create_modal.template.rate.label')}
                    type="number"
                    unit={unit}
                    onChangeUnit={handleChangeUnit}
                    options={[
                      { value: FeeTypeDisplay.Fixed, label: FeeTypeDisplay.Fixed },
                      { value: FeeTypeDisplay.Percentage, label: FeeTypeDisplay.Percentage },
                    ]}
                  />
                </Grid>

                <Grid item xs={2}>
                  <RHFTextField
                    name="payment_terms_days"
                    type="number"
                    label={t('admin-connections.create_modal.template.payment_terms_days')}
                  />
                </Grid>
                <Grid item xs={10}>
                  <RHFSelect
                    placeholder={t(
                      'admin-connections.create_modal.template.payment_terms.placeholder'
                    )}
                    name="payment_terms"
                    label={t('admin-connections.create_modal.template.payment_terms.label')}
                  >
                    <MenuItem
                      sx={{ textTransform: 'capitalize' }}
                      value={t('enums.payment_terms.following_signing')}
                    >
                      {t('enums.payment_terms.following_signing')}
                    </MenuItem>
                    <MenuItem
                      sx={{ textTransform: 'capitalize' }}
                      value={t('enums.payment_terms.following_start')}
                    >
                      {t('enums.payment_terms.following_start')}
                    </MenuItem>
                    <MenuItem
                      sx={{ textTransform: 'capitalize' }}
                      value={t('enums.payment_terms.invoice_issued')}
                    >
                      {t('enums.payment_terms.invoice_issued')}
                    </MenuItem>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField
                    multiline
                    name="special_conditions"
                    label={t('admin-connections.create_modal.template.special_conditions')}
                    type="text"
                    rows={2}
                  />
                </Grid>
              </Grid>
            )}
          </SelectableCard>
        </Grid>

        <Grid item xs={12}>
          <SelectableCard
            title={t('admin-connections.create_modal.terms_of_engagement.upload.title')}
            subtitle={t('admin-connections.create_modal.terms_of_engagement.upload.subtitle')}
            selected={selectedTermsType === ConnectionTermsType.Custom}
            onClick={() => onSelectTermsType(ConnectionTermsType.Custom)}
            flexDirection={selectedTermsType === ConnectionTermsType.Custom ? 'column' : 'row'}
            disabled={hasNoSubscription}
          >
            {selectedTermsType === ConnectionTermsType.Custom && (
              <>
                <Upload onDrop={handleDocumentUpload} sx={{ mt: 1 }} />

                {file && (
                  <UploadItemCard
                    sx={{ mt: 2 }}
                    title={(file as any).path}
                    onDeleteFile={() => setFile(undefined)}
                    uploading={creatingDocument || uploadingFile}
                  />
                )}
              </>
            )}
          </SelectableCard>
        </Grid>

        <Stack sx={{ py: 2, width: '100%' }} flexDirection="row" justifyContent="flex-end">
          <Button variant="outlined" onClick={onBack}>
            {t('common.back')}
          </Button>
          <LoadingButton
            loading={submitting || uploadingFile || creatingDocument}
            type="submit"
            variant="contained"
            disabled={disableContinue || submitting}
            sx={{ ml: 1 }}
            onClick={() =>
              [ConnectionTermsType.Terms_Of_Engagement, ConnectionTermsType.Custom].includes(
                selectedTermsType
              )
                ? nextPageHandler()
                : undefined
            }
          >
            {tenant === TenantType.Client ? t('common.next') : t('common.submit')}
          </LoadingButton>
        </Stack>
      </Grid>

      {
        activeToeTerms && (
          <ViewTermsModal
            open={termsPreviewControl.value}
            onClose={termsPreviewControl.onFalse}
            title={t('admin-connections.create_modal.terms_of_engagement.standard.title')}
            termsType={ConnectionTermsType.Terms_Of_Engagement}
            termsData={[{ type: "usage_term", content: activeToeTerms?.content }]}
          />
        )
      }

    </FormProvider>
  );
}
