import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import { useRef, useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MobileStepper
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { DocumentType } from 'src/services/files/file.types';
import { useGetMyProfileQuery } from 'src/services/candidates/candidates.service';
import { useActionResourceRequestMutation } from 'src/services/applications/applications.service';
import {
  ResourceType,
  ResourceRequestAction,
  ActionResourceRequestData,
  ApplicationResourceRequest
} from 'src/services/applications/applications.types';
import { IWorkExperience, IEducationHistory, IPreferredLocation, ISalaryExpectation, CandidateProfileDocument, CandidateProfileSectionType } from 'src/services/candidates/candidates.types';

import SteppedProfileSelector, { ProfileCurrentInformation, SteppedProfileSelectorHandle } from 'src/sections/jobs/applications/modals/resource-requests/stepped-profile-selector';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

import { SteppedDocumentSelector } from './stepped-document-selector';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  request: ApplicationResourceRequest;
  profileId: string;
};

const getAcceptProfileErrorMessage = (e: any, resource: ResourceType) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.MissingRequestedItems) {
      const sections = e?.data?.context?.item_types
        .map((item: string) =>
          resource === ResourceType.ProfileInformation
            ? i18n.t(`enums.candidate_profile_section.${item}`)
            : i18n.t(`enums.document_type.${item}`)
        )
        .join(', ');
      return i18n.t('applications.api.action_resource_request.accept_error_missing', { sections });
    }
  }
  return i18n.t('applications.api.action_resource_request.accept_error');
};

export default function ResourceRequestActionModal({ open, onClose, request, profileId }: Props) {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const tenantType = useOrgTenant();

  const denyConfirmControl = useBoolean();
  const closeConfirmControl = useBoolean();

  const profileInfoStepperRef = useRef<SteppedProfileSelectorHandle>(null);

  const [activeStep, setActiveStep] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<{ profile_document_id: string; type: DocumentType }[]>([]);

  const [updatedProfileInformation, setUpdatedProfileInformation] = useState<ProfileCurrentInformation>({});

  const { currentData: myProfile } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate
  });

  const [actionRequest, { isLoading }] = useActionResourceRequestMutation();

  useEffect(() => {
    setActiveStep(0);
    setErrorMsg(null);
    setSelectedDocuments([]);
    setUpdatedProfileInformation({});
  }, [open]);

  useEffect(() => {
    if (myProfile) {
      setUpdatedProfileInformation({
        [CandidateProfileSectionType.EXECUTIVE_SUMMARY]: myProfile.summary?.content ?? '', // added
        [CandidateProfileSectionType.JOB_TYPES]: myProfile.job_types ? myProfile.job_types.map((item) => item.type as string) : [],  // added
        [CandidateProfileSectionType.SALARY_EXPECTATIONS]: myProfile.salary_expectations ?? [],  // added
        [CandidateProfileSectionType.WORK_LOCATIONS]: myProfile.preferred_locations ?? [],  // added
        [CandidateProfileSectionType.WORK_RIGHTS]: myProfile.work_rights?.[0]?.work_right ?? '',  // added
        [CandidateProfileSectionType.WORK_STYLES]: myProfile.work_styles ? myProfile.work_styles.map((item) => item.style as string) : [],  // added
        [CandidateProfileSectionType.EDUCATION_LEVEL]: myProfile.education_level?.type as string ?? null, // added
        [CandidateProfileSectionType.WORK_EXPERIENCES]: myProfile.work_experiences ?? [],
        [CandidateProfileSectionType.NOTICE_PERIOD]: myProfile.notice_period?.type as string ?? null, // added
        [CandidateProfileSectionType.SKILLS]: myProfile.skills ? myProfile.skills.map((skill) => skill.name) : [],  // added
        [CandidateProfileSectionType.LINKS]: myProfile.links ?? [],  // added
        [CandidateProfileSectionType.EDUCATION_HISTORY]: myProfile.education_history ?? [],  // added
      });
    }
  }, [myProfile]);

  const handleNext = async () => {

    if (request.type === ResourceType.ProfileInformation) {
      const valid = await profileInfoStepperRef.current?.validate();
      if (!valid) {
        return;
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDocumentSelection = (type: DocumentType, document: CandidateProfileDocument | null) => {
    if (!document) {
      setSelectedDocuments((prev) => prev.filter((doc) => doc.type !== type));
      return;
    }

    setSelectedDocuments((prev) => {
      const existingIndex = prev.findIndex((doc) => doc.type === type);
      if (existingIndex !== -1) {
        return prev.map((doc) => {
          if (doc.type === type) {
            return { profile_document_id: document.id, type };
          }
          return doc;
        });
      }
      return [...prev, { profile_document_id: document.id, type }];
    });
  };

  const isRequestedProfileSection = (section: CandidateProfileSectionType) => request.items.some((item) => item.item_type === section)

  const onApproveRequest = async () => {
    try {

      const approveRequestDto: ActionResourceRequestData = {
        action: ResourceRequestAction.ACCEPT
      }

      if (request.type === ResourceType.Document) {
        approveRequestDto.documents = selectedDocuments;
      } else {

        const isValid = await profileInfoStepperRef.current?.validate();
        if (!isValid) {
          return;
        }

        const data = profileInfoStepperRef.current?.getData();

        approveRequestDto.summary = isRequestedProfileSection(CandidateProfileSectionType.EXECUTIVE_SUMMARY)
          ? { content: data[CandidateProfileSectionType.EXECUTIVE_SUMMARY] as string } : undefined;

        approveRequestDto.job_types = isRequestedProfileSection(CandidateProfileSectionType.JOB_TYPES)
          ? data[CandidateProfileSectionType.JOB_TYPES]?.map((item: string) => ({ type: item })) : undefined;

        approveRequestDto.salary_expectations = isRequestedProfileSection(CandidateProfileSectionType.SALARY_EXPECTATIONS)
          ? data[CandidateProfileSectionType.SALARY_EXPECTATIONS]?.map((item: ISalaryExpectation) => ({
            min_amount: item.min_amount,
            max_amount: item.max_amount,
            period: item.period
          })) : undefined;

        approveRequestDto.preferred_locations = isRequestedProfileSection(CandidateProfileSectionType.WORK_LOCATIONS)
          ? data[CandidateProfileSectionType.WORK_LOCATIONS]?.map((item: IPreferredLocation) => ({
            place_id: item.place_id,
            address: item.address,
            latitude: item.latitude,
            longitude: item.longitude
          })) : undefined;

        approveRequestDto.work_rights = isRequestedProfileSection(CandidateProfileSectionType.WORK_RIGHTS)
          ? [{ work_right: data[CandidateProfileSectionType.WORK_RIGHTS] }] : undefined;

        approveRequestDto.work_styles = isRequestedProfileSection(CandidateProfileSectionType.WORK_STYLES)
          ? data[CandidateProfileSectionType.WORK_STYLES]?.map((item: string) => ({ style: item })) : undefined;

        approveRequestDto.education_level = isRequestedProfileSection(CandidateProfileSectionType.EDUCATION_LEVEL)
          ? { type: data[CandidateProfileSectionType.EDUCATION_LEVEL] } : undefined;

        approveRequestDto.work_experiences = isRequestedProfileSection(CandidateProfileSectionType.WORK_EXPERIENCES)
          ? data[CandidateProfileSectionType.WORK_EXPERIENCES]?.map((item: IWorkExperience, index: number) => ({
            company_name: item.company_name,
            position_title: item.position_title,
            start: item.start,
            end: item.end,
            role_summary: item.role_summary,
            item_order: index + 1
          })) : undefined;

        approveRequestDto.notice_period = isRequestedProfileSection(CandidateProfileSectionType.NOTICE_PERIOD)
          ? { type: data[CandidateProfileSectionType.NOTICE_PERIOD] } : undefined;

        approveRequestDto.skills = isRequestedProfileSection(CandidateProfileSectionType.SKILLS)
          ? data[CandidateProfileSectionType.SKILLS]?.map((item: string) => ({ name: item })) : undefined;

        approveRequestDto.links = isRequestedProfileSection(CandidateProfileSectionType.LINKS)
          ? data[CandidateProfileSectionType.LINKS] : undefined;

        approveRequestDto.education_history = isRequestedProfileSection(CandidateProfileSectionType.EDUCATION_HISTORY)
          ? data[CandidateProfileSectionType.EDUCATION_HISTORY]?.map((item: IEducationHistory, index: number) => ({
            certification_name: item.certification_name,
            institution_name: item.institution_name,
            level: item.level,
            start_year: item.start_year,
            end_year: item.end_year
          })) : undefined;
      }

      await actionRequest({
        requestId: request.id,
        data: approveRequestDto,
      }).unwrap();

      onClose();
      enqueueSnackbar(t('applications.api.action_resource_request.accept_success'), {
        variant: 'success',
      });
    } catch (e) {
      setErrorMsg(getAcceptProfileErrorMessage(e, request.type));
      enqueueSnackbar(t('applications.api.action_resource_request.accept_error'), {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const confirmDenyRequest = async () => {
    denyConfirmControl.onTrue();
  }

  const onDenyRequest = async () => {
    try {
      await actionRequest({
        requestId: request.id,
        data: { action: ResourceRequestAction.REJECT },
      }).unwrap();
      enqueueSnackbar(t('applications.api.action_resource_request.reject_success'), {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar(t('applications.api.action_resource_request.reject_error'), {
        variant: 'error',
      });
    }
  };

  const readyToSubmit = useMemo(() => {
    if (request.type === ResourceType.Document) {
      return selectedDocuments.length === request.items.length;
    }

    if (request.type === ResourceType.ProfileInformation) {
      const requiredSections = request.items.map((item) => item.item_type);
      return requiredSections.every((section) => updatedProfileInformation[section as keyof ProfileCurrentInformation]);
    }

    return false;
  }, [request, selectedDocuments, updatedProfileInformation]);

  const handleClose = () => {
    closeConfirmControl.onTrue();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {t('applications.resource_requests.action.title')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', mt: 2 }}>

          <Box sx={{ mb: 4 }}>
            {request.type === ResourceType.Document ? (
              <SteppedDocumentSelector
                type={request.items[activeStep].item_type as DocumentType}
                onSelect={handleDocumentSelection}
                profileId={profileId}
                selectedDocument={selectedDocuments.find(
                  (doc) => doc.type === request.items[activeStep].item_type
                )}
              />
            ) : (
              <SteppedProfileSelector
                ref={profileInfoStepperRef}
                section={request.items[activeStep].item_type as CandidateProfileSectionType}
                currentInformation={updatedProfileInformation}
              />
            )}
          </Box>

          {errorMsg && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMsg}
            </Alert>
          )}


        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>
          <Stack direction="row">
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              {t('common.back')}
            </Button>

            <Button onClick={handleClose}>{t('common.cancel')}</Button>
          </Stack>


          <MobileStepper
            steps={request.items.length}
            position="static"
            activeStep={activeStep}
            nextButton={undefined}
            backButton={undefined}
            sx={{ justifyContent: 'center', flex: '1 1 auto' }}
          />

          {activeStep === request.items.length - 1 ? (
            <Stack direction="row" spacing={1}>

              <LoadingButton
                onClick={confirmDenyRequest}
                loading={isLoading}
                disabled={isLoading}
                color="error"
                variant="contained">
                {t('common.decline')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                onClick={onApproveRequest}
                loading={isLoading}
                disabled={!readyToSubmit}
              >
                {t('common.submit')}
              </LoadingButton>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <LoadingButton
                onClick={confirmDenyRequest}
                loading={isLoading}
                disabled={isLoading}
                color="error"
                variant="contained">
                {t('common.decline')}
              </LoadingButton>

              <Button
                variant="contained"
                onClick={handleNext}
                disabled={
                  (request.type === ResourceType.Document &&
                    !selectedDocuments.find((doc) => doc.type === request.items[activeStep].item_type))
                }
              >
                {t('common.next')}
              </Button>
            </Stack>
          )}
        </Box>
      </DialogActions>

      <Dialog
        open={denyConfirmControl.value}
        onClose={denyConfirmControl.onFalse}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t('applications.resource_requests.action.deny.title')}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Alert severity="warning">
              {t('applications.resource_requests.action.deny.confirm')}
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={denyConfirmControl.onFalse}>{t('common.cancel')}</Button>
            <LoadingButton
              variant="contained"
              onClick={onDenyRequest}
              loading={isLoading}
              disabled={isLoading}
              color="error"
            >
              {t('common.decline')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog
        open={closeConfirmControl.value}
        onClose={closeConfirmControl.onFalse}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t('components.close_confirm.title')}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Alert severity="warning">
              {t('components.close_confirm.message')}
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={closeConfirmControl.onFalse}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                closeConfirmControl.onFalse();
                onClose();
              }}
            >
              {t('common.discard')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

    </Dialog >
  );
}