import {
  Box,
  Grid,
} from '@mui/material';

import { useTranslate } from 'src/locales';

import RHFMapsAutocomplete from 'src/components/hook-form/rhf-maps-autocomplete';

export default function WorkplaceLocationEditor() {

  const { t } = useTranslate();

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ mb: 3 }}>
          <RHFMapsAutocomplete
            name="work_locations"
            label="Locations"
            options={[]}
          />
        </Box>
      </Grid>
    </Grid>
  );
}